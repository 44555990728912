import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';
import { UserModel } from '../../models/user.model';
import { UserProfileModel } from '../../models/userProfile.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _userInitialization: UserModel = {
    email: '',
    id: '',
    status: 'offline',
    name: '',
    roles: [],
  };
  private _userProfileInitialization: UserProfileModel = {
    debtors: [],
    email: '',
    lastName: '',
    name: '',
    phone: '',
    role: '',
    rut: '',
    status: '',
    userId: '',
  };
  private _user: UserModel;
  private _userProfile: UserProfileModel;

  userChanged: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(
    this._userInitialization
  );

  constructor(private http: HttpClient) {
    this._user = this._userInitialization;
    this._userProfile = this._userProfileInitialization;
  }

  /**
   * Configura el usuario activo y su perfil.
   */
  async setUser(user: UserModel): Promise<void> {
    this._user = user;
    this._getUSerProfileAPI().subscribe({
      next: (userProfile: UserProfileModel): void => {
        this._userProfile = userProfile;
        // Informamos a los componentes.
        this.userChanged.next(this._user);
      },
    });
  }

  /**
   * Retorna el usuario activo.
   */
  getUser(): UserModel {
    return { ...this._user };
  }

  /**
   * Retorna el perfil del usuario activo.
   */
  getUserProfile(): UserProfileModel {
    return { ...this._userProfile };
  }

  /**
   * Consulta la API de usuarios.
   * @private
   */
  private _getUSerProfileAPI(): Observable<UserProfileModel> {
    let url: string = `${environment.apiUrl}user/search?email=${this._user.email}`;
    return this.http.get<{ data: UserProfileModel }>(url).pipe(
      map((response: { data: UserProfileModel }) => {
        return response.data;
      })
    );
  }
}
