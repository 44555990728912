import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import Swal from 'sweetalert2';
import { UserProfileModel } from '../models/userProfile.model';

interface Preference {
  motive: string;
  motiveText: string;
  receive: boolean;
  originalReceive: boolean;
  cambios?: boolean;
}

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
})
export class NotificacionesComponent implements OnInit, OnDestroy {
  usuario: UserModel;
  userProfile: UserProfileModel;
  mailPreferences: Preference[] = [];
  isGettingMailPreferences: boolean = true;
  isUpdatingPreferences: boolean = true;
  endpoint: string = environment.apiUrl;
  cambiosEnPreferencias: boolean = false;
  loading: boolean = false;
  cambiosRealizados: boolean = false;

  private userSubscription: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private _userService: UserService,
    private router: Router
  ) {
    this.usuario = this._userService.getUser();
    this.userProfile = this._userService.getUserProfile();
  }

  ngOnInit(): void {
    this.userSubscription = this._userService.userChanged.subscribe(
      (user: UserModel): void => {
        console.log('notificaciones - usuario', this.usuario);
        if (user.status === 'online') {
          this.usuario = user;
          this.userProfile = this._userService.getUserProfile();
          this.getMailPreferences();
          this.loading = true;
        }
      }
    );
  }
  mostrarAlerta(
    texto: string,
    icon: any,
    redireccionar: boolean = false,
    ruta?: string
  ) {
    Swal.fire({
      title: texto,
      icon: icon,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002948',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed && redireccionar) {
        if (ruta == 'notificaciones') {
          window.location.reload();
        } else if (ruta) {
          this.router.navigate([ruta]);
        } else {
          console.error(
            'No se proporcionó una ruta válida para la redirección.'
          );
        }
      }
    });
  }

  mostrarOtrasColumnas(): boolean {
    return this.mailPreferences.some(
      (preference) =>
        !preference.motive.includes('REQUEST') &&
        !preference.motive.includes('ORDER')
    );
  }

  getMailPreferences(): void {
    const endpoint: string = `${this.endpoint}email_preferences?user_id=${this.userProfile.userId}`;
    this.http.get<any>(endpoint).subscribe(
      (response) => {
        this.mailPreferences = response.data.preferences.map(
          (preference: Preference) => ({
            ...preference,
            originalReceive: preference.receive, // Almacenar el estado original de receive
          })
        );
        this.isGettingMailPreferences = false;
        this.isUpdatingPreferences = false;
      },
      (error) => {
        console.error('Error al obtener las preferencias de correo:', error);
        this.isGettingMailPreferences = false;
      },
      () => {
        this.loading = false; // Se establece loading en false cuando la solicitud está completa
      }
    );
  }

  toggleReceive(preference: Preference): void {
    preference.receive = !preference.receive; // Toggle de la preferencia
    preference.cambios = preference.receive !== preference.originalReceive; // Marcar si hay cambios en esta preferencia

    // Verificar si hay al menos una preferencia con cambios pendientes
    this.cambiosEnPreferencias = this.mailPreferences.some((p) => p.cambios);
  }

  cancelar(): void {
    window.location.reload();
  }

  actualizarPreferenciasDeCorreo(): void {
    this.loading = true;
    const preferencesPayload = {
      preferences: this.mailPreferences.map((preference) => ({
        motive: preference.motive,
        motiveText: preference.motiveText,
        receive: preference.receive,
      })),
      userId: this.userProfile.userId,
    };

    // Send an HTTP PUT request to update preferences
    const endpoint = `${this.endpoint}email_preferences`;
    this.http.put(endpoint, preferencesPayload).subscribe(
      () => {
        this.mostrarAlerta(
          'Notificaciones actualizadas correctamente',
          'success',
          true,
          'notificaciones'
        );
        this.cambiosRealizados = false;
      },
      (error) => {
        // Handle error, e.g., show an error message
        console.error('Error updating preferences:', error);
      }
    );
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    console.log('notificaciones - subscripciones deshechas.');
  }
}
