import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MaterialModel } from '../../models/material.model';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { DeliveryWindow } from '../../models/deliveryWindow.model';

@Injectable({
  providedIn: 'root',
})
export class MaterialsService {
  private materials: MaterialModel[] = [];
  private materialsObject: { [materialId: string]: MaterialModel } = {};

  materialsChanged: BehaviorSubject<MaterialModel[]> = new BehaviorSubject<
    MaterialModel[]
  >(this.materials);
  materialsObjectChanged: BehaviorSubject<{
    [materialId: string]: MaterialModel;
  }> = new BehaviorSubject(this.materialsObject);

  constructor(private http: HttpClient) {}

  /**
   * Configura el maestro de materiales
   */
  async setMaterials(): Promise<void> {
    this._getMaterialsAPI().subscribe({
      next: (materials: MaterialModel[]): void => {
        this.materials = materials;
        materials.forEach((material: MaterialModel): void => {
          this.materialsObject[material.materialId] = material;
        });
        console.log('materialsObject', this.materialsObject);
        console.log('materialsList', this.materials);
        // Informamos a los componentes.
        this.materialsChanged.next(this.materials);
        this.materialsObjectChanged.next(this.materialsObject);
      },
    });
  }

  /**
   * Retorna el maestro de materiales
   */
  getMaterialsObject(): { [index: string]: MaterialModel } {
    return { ...this.materialsObject };
  }

  /**
   * Retorna el maestro de materiales
   */
  getMaterialsList(): MaterialModel[] {
    return this.materials.slice();
  }

  /**
   * Consulta la API de materiales.
   * @private
   */
  private _getMaterialsAPI(): Observable<MaterialModel[]> {
    const url: string = `${environment.apiUrl}materials`;
    return this.http.get<{ data: MaterialModel[] }>(url).pipe(
      map((response: { data: MaterialModel[] }) => {
        return response.data;
      })
    );
  }
}
