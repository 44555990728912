<header>
  <app-header></app-header>
</header>
<section
  *ngIf="!isGettingMailPreferences && !isUpdatingPreferences && mailPreferences"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title">Notificaciones</div>
            <p
              style="
                text-align: left;
                font-size: 15px;
                max-width: 80%;
                color: black;
                text-transform: none;
              "
            >
              Encienda un concepto para recibir notificaciones asociadas o
              apáguelo para dejar de recibirlas.
            </p>
          </div>

          <div>
            <div class="row">
              <div class="col" style="margin-left: 20px">
                <h5>Solicitudes</h5>
                <table
                  class="table table-striped table-hover mail-preferences"
                  aria-label="Tabla de preferencias de correo solicitudes"
                >
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let preference of mailPreferences">
                      <tr
                        class="preference-item"
                        *ngIf="preference.motiveText.includes('Solicitud')"
                      >
                        <td class="preference-info">
                          {{ preference.motiveText }}
                        </td>
                        <td class="toggle-switch">
                          <label class="switch">
                            <input
                              type="checkbox"
                              [checked]="preference.receive"
                              (change)="toggleReceive(preference)"
                            />
                            <span class="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="col">
                <h5>Pedidos</h5>
                <table
                  class="table table-striped table-hover mail-preferences"
                  aria-label="Tabla de preferencias de correo pedidos"
                >
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let preference of mailPreferences">
                      <tr
                        class="preference-item"
                        *ngIf="preference.motive.includes('ORDER')"
                      >
                        <td class="preference-info">
                          {{ preference.motiveText }}
                        </td>
                        <td class="toggle-switch">
                          <label class="switch small">
                            <input
                              type="checkbox"
                              [checked]="preference.receive"
                              (change)="toggleReceive(preference)"
                            />
                            <span class="slider round small"></span>
                          </label>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="col" style="margin-right: 20px">
                <h5>Cargas</h5>
                <table
                  class="table table-striped table-hover mail-preferences"
                  aria-label="Tabla de preferencias de correo de cargas"
                >
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let preference of mailPreferences">
                      <tr
                        class="preference-item"
                        *ngIf="
                          preference.motive.includes('SHOPPING_CART_REQUEST')
                        "
                      >
                        <td class="preference-info">
                          {{ preference.motiveText }}
                        </td>
                        <td class="toggle-switch">
                          <label class="switch">
                            <input
                              type="checkbox"
                              [checked]="preference.receive"
                              (change)="toggleReceive(preference)"
                            />
                            <span class="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="col" *ngIf="mostrarOtrasColumnas()">
                <h5>Otras</h5>
                <table
                  class="table table-striped table-hover mail-preferences"
                  aria-label="Tabla de preferencias de correo otras categorias"
                >
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let preference of mailPreferences">
                      <tr
                        class="preference-item"
                        *ngIf="
                          !preference.motive.includes('REQUEST') &&
                          !preference.motive.includes('ORDER') &&
                          !preference.motive.includes('Carga')
                        "
                      >
                        <td class="preference-info">
                          {{ preference.motiveText }}
                        </td>
                        <td class="toggle-switch">
                          <label class="switch small">
                            <input
                              type="checkbox"
                              [checked]="preference.receive"
                            />
                            <span class="slider round small"></span>
                          </label>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="col-md-12 mt-40 mb-40 botones"
              style="margin-bottom: 50px"
            >
              <button type="button" class="btn btn-danger" (click)="cancelar()">
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                (click)="actualizarPreferenciasDeCorreo()"
                [disabled]="!cambiosEnPreferencias"
                style="margin-right: 20px"
              >
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="loading" class="overlay">
  <div class="loader"></div>
</div>
