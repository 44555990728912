import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import Swal from 'sweetalert2';
import { DeliveryWindowService } from '../services/delivery-windows/delivery-window.service';
import { DeliveryWindow } from '../models/deliveryWindow.model';
import { PetitionerService } from '../services/petitioner/petitioner.service';
import { PetitionerModel } from '../models/Petitioner.model';
import { DebtorModel } from '../models/debtors.model';
import { UserProfileModel } from '../models/userProfile.model';
import { MaterialModel } from '../models/material.model';
import { MaterialsService } from '../services/materials/materials.service';
import { PlantsService } from '../services/plants/plants.service';
import { PlantModel } from '../models/plant.model';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ShoppingCartService } from '../services/shoppingCart/shopping-cart.service';
import {
  MaterialCartModel,
  ShoppingCartModel,
} from '../models/shopppingCart.model';
import { VolumeModel } from '../models/volume.model';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrl: './pedidos.component.css',
})
export class PedidosComponent implements OnInit, OnDestroy {
  usuario: UserModel;
  userProfile: UserProfileModel;
  petitioners: string[] = [];
  solicitante: PetitionerModel[] = [];
  minDate: any;
  maxDate: any;
  ventana_entrega: DeliveryWindow[] = [];
  materialsArray: MaterialModel[] = [];
  planta: { plantId: string; name: string }[] = [];
  material: { materialId: string; name: string }[] = [];
  volumen: VolumeModel[] = [];
  volumenData: string[] = [];
  validaSelecTipoCamion: boolean = false;
  sumaTotal: number = 0;
  materialsOfPetitioner: MaterialModel[] = [];
  mappedMaterials: {
    [key: string]: {
      materialId: string;
      description: string;
      shortDescription: string;
    };
  } = {};
  IdCamion: any;
  rangoHorario: any[] = [];
  loading: boolean = false;
  debtorList: DebtorModel[] = [];
  private userSubscription: Subscription = new Subscription();
  private materialsSubscription: Subscription = new Subscription();
  selectedPetitioner: PetitionerModel | undefined;
  allPlants: { [index: string]: PlantModel } = {};
  private plantsSubscription: Subscription = new Subscription();
  private deliveryWindowsSubscription: Subscription = new Subscription();
  requestId: string = '';
  form: FormGroup;
  materialesDisponibles: MaterialModel[] = [];
  isEdit: boolean = false;
  selectedEdit: ShoppingCartModel | undefined;

  constructor(
    private router: Router,
    private _userService: UserService,
    private route: ActivatedRoute,
    private deliveryWindowsService: DeliveryWindowService,
    private petitionerService: PetitionerService,
    private materialsService: MaterialsService,
    private plantsService: PlantsService,
    private fb: FormBuilder,
    private shoppingCartService: ShoppingCartService
  ) {
    this.loading = true;
    this.usuario = this._userService.getUser();
    this.userProfile = this._userService.getUserProfile();
    this.materialsArray = this.materialsService.getMaterialsList();
    this.allPlants = this.plantsService.getPlantsObject();
    this.form = this.fb.group({
      createdBy: new FormControl(''),
      user: new FormControl(''),
      debtor: new FormControl(''),
      petitionerId: new FormControl(''),
      requestedDeliveryTimeWindow: new FormControl(''),
      requestedDeliveryDate: new FormControl(''),
      purchaseOrder: new FormControl('', [
        Validators.pattern('/^[a-zA-Z0-9]*$/'),
      ]),
      purchaseOrderDate: new FormControl(''),
      purchaseOrderExternalReference: new FormControl('', [
        Validators.pattern('/^[a-zA-Z0-9]*$/'),
      ]),
      HES: new FormControl('', [Validators.pattern('/^[a-zA-Z0-9]*$/')]),
      HESDate: new FormControl(''),
      HESExternalReference: new FormControl('', [
        Validators.pattern('/^[a-zA-Z0-9]*$/'),
      ]),
      comment: new FormControl(''),
      plantId: new FormControl(''),
      volume: new FormControl(''),
      unitOfMeasure: new FormControl(''),
      compartmentsNumber: new FormControl(''),
      volumesDistribution: new FormControl(''),
      materialId: new FormControl(''),
      volumes: this.fb.array([]),
      materials: this.fb.group({}),
    });
  }

  @ViewChild('exampleModal') exampleModal!: ElementRef;
  get materialsFormArray() {
    return this.form.get('materials') as FormGroup;
  }

  ngOnInit() {
    this.userSubscription = this._userService.userChanged.subscribe(
      (user: UserModel): void => {
        if (user.status === 'online') {
          this.usuario = user;
          this.userProfile = this._userService.getUserProfile();
          this.fetchPetitioners();
          this.route.url.subscribe((urlSegment) => {
            let rutaActual = urlSegment
              .map((segment) => segment.path)
              .join('/');
            if (rutaActual.includes('edit-solicitud/')) {
              this.isEdit = true;
              this.getEdit();
            }
          });
        }
      }
    );
    this.initializeModal();
    this.ventana_entrega = this.deliveryWindowsService.getDeliveryWindows();
    this.suscriptionsMasters();
    this.minDate = new Date().toISOString().split('T')[0];
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 30);
    this.maxDate = tomorrow.toISOString().split('T')[0];
  }

  setFormValues(data: ShoppingCartModel | undefined): void {
    if (data === undefined) {
      return;
    }
    this.form.patchValue({ debtor: data.debtor });
    this.onChangeDebtor();
    this.form.patchValue({
      createdBy: data.createdBy,
      petitionerId: data.petitionerId,
      purchaseOrder: data.purchaseOrder,
      purchaseOrderDate: data.purchaseOrderDate,
      purchaseOrderExternalReference: data.purchaseOrderExternalReference,
      HES: data.HES,
      HESDate: data.HESDate,
      HESExternalReference: data.HESExternalReference,
      comment: data.comment,
      plantId: data.plantId,
    });
    this.onChangeSolicitante();
    this.onChangePlanta();
    this.form.controls['volumesDistribution'].setValue(
      data.volumes[0].volumesDistribution
    );
    //TODO Esta mal el nombre de la variable creo
    this.IdCamion = data.volumes[0].volumesDistribution;
  }

  setDataBackOnTruck(data: ShoppingCartModel | undefined) {
    if (data === undefined) {
      return;
    }
    let temvol: Array<string> = this.form.value.volumesDistribution.split('|');
    // Crear una copia del array para no modificar el original
    let materialsCopy = [...data.materials];
    temvol.forEach((capacidad, index) => {
      let name = index.toString() + capacidad;
      // Buscar el índice del material en la copia
      let matIndex = materialsCopy.findIndex(
        (material) => material.volume === capacidad
      );
      if (matIndex !== -1) {
        let mat = materialsCopy[matIndex];
        this.form.get('materials')?.get(name)?.setValue(mat.materialId);
        // Eliminar el material ya asignado de la copia
        materialsCopy.splice(matIndex, 1);
      }
    });
  }

  getEdit() {
    // traer en caso de editar el id desde los parametros y
    this.route.params.subscribe(async (params: Params): Promise<void> => {
      this.requestId = params['id'];
    });
    if (this.requestId !== '') {
      this.shoppingCartService
        .getShoppingCart(this.userProfile.userId)
        .subscribe({
          next: (shoppingCart) => {
            // Filtrar la solicitud correspondiente al ID requerido
            this.selectedEdit = shoppingCart.find(
              (shoppingItem) =>
                shoppingItem.shoppingCartRequestsId === this.requestId
            );
            this.setFormValues(this.selectedEdit);
          },
        });
    }
  }

  autoFillDate(event: any, id: any): void {
    const numerohes = event.target.value;
    if (numerohes) {
      this.form.controls[id].setValue(new Date().toISOString().split('T')[0]);
    } else {
      this.form.controls[id].reset();
    }
  }

  mostrarAlerta(
    texto: string,
    icon: any,
    redireccionar: boolean = false,
    ruta?: string
  ) {
    Swal.fire({
      title: texto,
      icon: icon,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002948',
    }).then((result) => {
      if (result.isConfirmed && redireccionar) {
        // El usuario ha hecho clic en el botón OK y se debe redirigir
        // Verificar si se proporcionó una ruta válida
        if (ruta) {
          this.router.navigate([ruta]);
        } else {
          console.error(
            'No se proporcionó una ruta válida para la redirección.'
          );
        }
      }
    });
  }

  private initializeModal() {
    if (this.exampleModal) {
      this.exampleModal.nativeElement.addEventListener(
        'hidden.bs.modal',
        () => {
          // Realizar cualquier acción adicional al cerrar el modal
        }
      );
    }
  }

  limpiarFormulario() {
    const obsr = document.getElementById('observacion') as HTMLTextAreaElement;
    obsr.value = '';
    this.planta = [];
    this.form.reset();
    this.form.patchValue({
      debtor: '',
      petitionerId: '',
      createdBy: '',
      user: '',
      requestedDeliveryTimeWindow: '',
      requestedDeliveryDate: '',
      purchaseOrder: '',
      purchaseOrderDate: '',
      purchaseOrderExternalReference: '',
      HES: '',
      HESDate: '',
      HESExternalReference: '',
      comment: '',
      plantId: '0',
      volume: '',
      unitOfMeasure: '',
      compartmentsNumber: '',
      volumesDistribution: '',
      materialId: '',
      volumes: [],
      materials: {},
    });
    this.validaSelecTipoCamion = false;

    this.petitioners = [];
    this.volumen = [];
    this.rangoHorario = [];
    this.solicitante = [];
    console.log(this.form.value);
  }

  fetchPetitioners(): void {
    this.userProfile.debtors.forEach((debtor: DebtorModel) => {
      this.debtorList.push({
        debtor: debtor.debtor,
        name: debtor.name,
        petitioners: debtor.petitioners,
      });
    });

    if (this.debtorList.length === 1) {
      const selectedDebtorId = this.debtorList[0].debtor; // Obtener el ID del primer deudor
      this.form.patchValue({ debtor: selectedDebtorId });
      // Llamar manualmente a onChangeDebtor con el ID seleccionado
      this.onChangeDebtor();
    }
    this.loading = false;
  }

  selectDate(event: any): void {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    this.loading = true;
    this.form.patchValue({ requestedDeliveryTimeWindow: '' });
    this.obtenerRangoHorario(currentTime, selectedDate, currentDate);
  }

  obtenerRangoHorario(
    currentTime: number,
    selectedDate: Date,
    currentDate: Date
  ): DeliveryWindow[] {
    const selectedDateISOString = selectedDate.toISOString().split('T')[0];
    const currentDateISOString = currentDate.toISOString().split('T')[0];
    const esMismaFecha = selectedDateISOString === currentDateISOString;
    const currentDatePlusOne = new Date(currentDate);
    currentDatePlusOne.setDate(currentDatePlusOne.getDate() + 1);
    const currentDatePlusOneISOString = currentDatePlusOne
      .toISOString()
      .split('T')[0];
    const tomorrow = selectedDateISOString === currentDatePlusOneISOString;
    if (esMismaFecha) {
      this.rangoHorario = this.ventana_entrega.filter((rango) => {
        return rango.windowId === '004';
      });
    } else if (tomorrow) {
      let horaActual = Math.floor(currentTime / 60);
      if (horaActual < 14) {
        this.rangoHorario = this.ventana_entrega;
      } else if (horaActual >= 17) {
        this.rangoHorario = this.ventana_entrega.filter((rango) => {
          return rango.windowId === '004';
        });
      } else {
        this.rangoHorario = this.ventana_entrega.filter((rango) => {
          //aqui habil, pm y cualquiera
          return (
            rango.windowId === '005' ||
            rango.windowId === '002' ||
            rango.windowId === '004'
          );
        });
      }
    } else {
      // Si es otra fecha, asignar todos los rangos disponibles
      this.rangoHorario = this.ventana_entrega;
    }
    this.loading = false;
    return this.rangoHorario;
  }

  procesarPetitioners(): void {
    this.loading = true;
    this.petitionerService.getPetitioners(this.petitioners).subscribe(
      (petitioners) => {
        this.solicitante = petitioners;
        if (this.solicitante.length === 1) {
          this.form.controls['petitionerId'].setValue(
            this.solicitante[0].petitionerId
          );
        }
        this.onChangeSolicitante();
        this.loading = false;
      },
      () => {
        // Manejo de errores
        this.mostrarAlerta('Error al procesar algún petitioners', 'error');
        // Aquí puedes agregar cualquier lógica adicional para manejar el error, como mostrar un mensaje al usuario.
      }
    );
  }
  //NUEVO
  agregarSolicitud(): void {
    let form = this.form.value;
    this.loading = true;

    if (!form.petitionerId || form.petitionerId === '0') {
      this.mostrarAlerta('Por favor, ingrese un solicitante', 'info');
      this.loading = false;
    } else if (!form.requestedDeliveryDate) {
      this.mostrarAlerta('Por favor, selecciona una Fecha de Entrega.', 'info');
      this.loading = false;
    } else if (!form.requestedDeliveryTimeWindow) {
      this.mostrarAlerta(
        'Por favor, selecciona una Ventana de Entrega.',
        'info'
      );
      this.loading = false;
    } else if (!form.plantId || form.plantId === '0') {
      this.mostrarAlerta('Por favor, selecciona una Planta.', 'info');
      this.loading = false;
    } else if (!form.volumesDistribution || form.volumesDistribution === '0') {
      this.mostrarAlerta('Por favor, selecciona un Tipo de Camión.', 'info');
      this.loading = false;
    } else if (!this.form.get('materials')?.valid) {
      this.mostrarAlerta(
        'Por favor, selecciona al menos un valor para cada columna.',
        'info'
      );
      this.loading = false;
      return;
    } else {
      let postData: ShoppingCartModel = {
        createdBy: this.userProfile.userId,
        debtor: form.debtor,
        petitionerId: form.petitionerId,
        requestedDeliveryTimeWindow: form.requestedDeliveryTimeWindow,
        requestedDeliveryDate: form.requestedDeliveryDate,
        purchaseOrder: form.purchaseOrder,
        purchaseOrderDate: form.purchaseOrderDate,
        purchaseOrderExternalReference: form.purchaseOrderExternalReference,
        HES: form.HES,
        HESDate: form.HESDate,
        HESExternalReference: form.HESExternalReference,
        comment: form.comment,
        plantId: form.plantId,
        volumes: form.volumes,
        materials: this.getMaterialsForPost(),
      };

      if (this.isEdit) {
        postData.shoppingCartRequestsId = this.requestId;
        this.shoppingCartService.putShoppingCart(postData).subscribe({
          next: (data) => {
            this.loading = false;
            this.mostrarAlerta('Solicitud editada correctamente', 'success');
            this.router.navigate(['/solicitud'], { state: { postData } });
          },
          error: (error) => {
            this.mostrarAlerta(
              'Error: ' + error.error.message,
              'error',
              true,
              '/home'
            );
          },
        });
      } else {
        this.shoppingCartService.postShoppingCart(postData).subscribe(
          (response) => {
            this.loading = false;
            this.mostrarAlerta('Solicitud agregada correctamente', 'success');
            this.router.navigate(['/solicitud'], { state: { postData } });
          },
          (error) => {
            this.mostrarAlerta('Error: ' + error.error.message, 'error');
          }
        );
      }
    }
  }
  //NUEVO
  getMaterialsForPost(): MaterialCartModel[] {
    let materials: MaterialCartModel[] = [];
    const materialform = this.form.get('materials') as FormGroup;
    if (materialform) {
      const materialValues = materialform.value;
      Object.keys(materialValues).forEach((key) => {
        const volume = key.substring(1);
        const IdMaterial: string = materialValues[key] || '';
        materials.push({
          unitOfMeasure: 'M3',
          materialId: IdMaterial,
          volume: volume,
        });
      });
    }
    return materials;
  }

  // Evento onChange de select de planta
  onChangeSolicitante(): void {
    this.volumen = [];
    if (this.form.value.petitionerId) {
      this.fetchPetitioner(this.form.value.petitionerId);
    }
  }

  handlePlantsIds(plantsIds: any[]): void {
    this.planta = [];
    for (const id of plantsIds) {
      const planta = this.allPlants[id]; // Acceso directo al objeto
      if (planta) {
        this.planta.push(planta); // Solo agrega si existe
      }
    }
    if (this.planta.length === 1) {
      this.form.patchValue({ plantId: this.planta[0].plantId });
    }
    this.onChangePlanta();
  }

  handleMaterialsIds(materialIds: any[]): void {
    // Filtrar los materiales que coincidan con los IDs proporcionados
    const filteredMaterials = this.materialsArray.filter((material) =>
      materialIds.includes(material.materialId)
    );
    this.materialsOfPetitioner = filteredMaterials;
    // Mapear los materiales filtrados al objeto mappedMaterials
    this.materialesDisponibles = filteredMaterials;
    this.mappedMaterials = filteredMaterials.reduce(
      (result: any, material: MaterialModel) => {
        result[material.materialId] = {
          ...material,
        };
        return result;
      },
      {}
    );
    if (!this.mappedMaterials) {
      this.mostrarAlerta('No hay ningun material para ese solicitante', 'info');
    }
  }

  fetchPetitioner(value: string): void {
    let plantsIds: any[] = [];
    this.selectedPetitioner = this.solicitante.find(
      (solicitante) => solicitante.petitionerId === value
    );
    const uniquePlantIdsSet = new Set(
      this.selectedPetitioner?.materials.map(
        (material: { plantId: string }) => material.plantId
      )
    );
    plantsIds = Array.from(uniquePlantIdsSet);
    this.handlePlantsIds(plantsIds);
  }

  // Evento onChange de select de planta
  onChangePlanta(): void {
    let plantId = this.form.value.plantId;
    if (plantId === '0') {
      this.volumen = [];
      this.onChangeCamion();
      this.validaSelecTipoCamion = false;
    } else {
      this.form.controls['plantId'].setValue(plantId);
      let planta: PlantModel = this.allPlants[plantId];
      const uniqueMaterialIdsSet = new Set(
        this.selectedPetitioner?.materials
          .filter((material) => plantId === material.plantId)
          .map((material) => material.materialId)
      );
      let materialIds: any[] = [];
      materialIds = Array.from(uniqueMaterialIdsSet);
      this.handleMaterialsIds(materialIds);
      this.volumen = planta.volumes;
      this.onChangeCamion();
    }
  }
  //NUEVO
  initializeMaterials(): FormGroup[] {
    return Object.keys(this.mappedMaterials).map((key) => {
      return this.fb.group({
        materialId: [this.mappedMaterials[key].materialId],
        volumes: this.fb.array(this.volumenData.map(() => false)), // Inicializa checkboxes en falso
      });
    });
  }
  //NUEVO
  onChangeCamion(): void {
    this.validaSelecTipoCamion = false;

    const volumen = this.form.value.volumesDistribution;
    if (!volumen) return;

    const camion = this.volumen.find((x) => x.volumesDistribution === volumen);
    const volumenArray = volumen.split('|');

    this.actualizarFormularioCamion(camion);
    this.actualizarMateriales(volumenArray);
    this.restaurarDatosEdicion(camion);

    this.validaSelecTipoCamion = volumenArray.length > 0;
  }

  private actualizarFormularioCamion(camion: any): void {
    this.form.controls['volume'].setValue(camion?.volume || '');
    this.form.controls['compartmentsNumber'].setValue(
      camion?.compartmentsNumber || ''
    );
    this.form.controls['unitOfMeasure'].setValue(camion?.unitOfMeasure || '');

    const volumesArray = this.form.get('volumes') as FormArray;
    volumesArray.clear();
    if (camion) volumesArray.push(this.fb.group(camion));

    this.form.removeControl('materials');
    this.form.updateValueAndValidity();
  }

  private actualizarMateriales(volumenData: string[]): void {
    this.volumenData = volumenData;
    const materialForm = this.fb.group({});

    this.volumenData.forEach((capacidad, index) => {
      const name = `${index}${capacidad}`;
      const selectedMaterial =
        this.materialesDisponibles.length === 1
          ? this.materialesDisponibles[0].materialId
          : '';
      materialForm.addControl(
        name,
        new FormControl(selectedMaterial, Validators.required)
      );
    });

    this.form.setControl('materials', materialForm);
  }

  private restaurarDatosEdicion(camion: any): void {
    if (this.selectedEdit && this.isEdit) {
      const selectedVolume = this.selectedEdit.volumes[0].volumesDistribution;
      if (this.form.controls['volumesDistribution'].value === selectedVolume) {
        this.setDataBackOnTruck(this.selectedEdit);
      }
    }
  }

  //NUEVO
  toggleRadio(id: string, value: string): void {
    const control = this.form.get('materials')?.get(id);
    if (control?.value === value) {
      control.setValue(null); // Deseleccionar si ya estaba seleccionado
    } else {
      control?.setValue(value); // Seleccionar el nuevo valor
    }
  }
  //NUEVO
  isChecked(j: number, volume: any, materialId: string): boolean {
    const materials = this.form.get('materials') as FormGroup;
    const controlName = `${j}${volume}`;
    const control = materials?.get(controlName);
    return control?.value === materialId;
  }
  //NUEVO
  getSuma(materialID: string): number {
    let sum = 0;
    const materialform = this.form.get('materials') as FormGroup;
    if (materialform) {
      const materialValues = materialform.value;
      Object.keys(materialValues).forEach((key) => {
        const value: string = materialValues[key] || '';
        if (value.trim() === materialID) {
          const numericKey = parseInt(key.substring(1), 10);
          sum = sum + numericKey;
        }
      });
    }
    return sum;
  }

  onChangeDebtor() {
    // Obtener el ID del deudor seleccionado
    const selectedDebtorId = this.form.value.debtor;
    this.petitioners = [];
    this.volumen = [];
    this.planta = [];
    this.IdCamion = '';
    this.validaSelecTipoCamion = false;
    this.form.patchValue({
      petitionerId: '',
      plantId: '0',
      volume: '',
      unitOfMeasure: '',
      compartmentsNumber: '',
      volumesDistribution: '',
      materialId: '',
      volumes: [],
      materials: {},
    });
    this.form.value.petitionerId = '';
    // Encontrar el deudor seleccionado en la lista de deudores
    const selectedDebtor = this.debtorList.find(
      (debtor) => debtor.debtor === selectedDebtorId
    );
    // Verificar si se encontró el deudor seleccionado
    if (selectedDebtor) {
      // Acceder a la lista de solicitantes del deudor seleccionado
      this.petitioners = selectedDebtor.petitioners;
      this.procesarPetitioners();
    }
  }

  suscriptionsMasters() {
    this.plantsSubscription = this.plantsService.plantsObjectChanged.subscribe(
      (plantsIndex) => {
        this.allPlants = plantsIndex;
      }
    );
    this.deliveryWindowsSubscription =
      this.deliveryWindowsService.deliveryWindowsChanged.subscribe(
        (deliveryWindows) => {
          this.ventana_entrega = deliveryWindows;
        }
      );
    this.materialsSubscription =
      this.materialsService.materialsChanged.subscribe(
        (materialsObject): void => {
          this.materialsArray = materialsObject;
        }
      );
  }
  cancelarSolicitud() {
    this.router.navigate(['/solicitud']);
  }

  ngOnDestroy(): void {
    this.plantsSubscription.unsubscribe();
    this.deliveryWindowsSubscription.unsubscribe();
    this.materialsSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    console.log('pedidos - subscripciones deshechas.');
  }
}
