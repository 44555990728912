import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { RequestModel } from '../../models/request.model';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  endpoint: string = environment.apiUrl;
  nextToken = new BehaviorSubject<string>('');
  constructor(private http: HttpClient) {}

  getRequest(
    petitioner_id: string,
    status: string,
    nextToken?: string
  ): Observable<RequestModel[]> {
    let url = `${this.endpoint}/requests?petitioner_id=${petitioner_id}&active=${status}`;
    if (nextToken) {
      url = `${url}&next_token=${nextToken}`;
    }
    return this.http
      .get<{ data: RequestModel[]; next_token: string }>(url)
      .pipe(
        map((response) => {
          this.nextToken.next(response.next_token ?? null);
          const data = response.data ?? [];
          if (data?.length > 0) {
            response.data.map((request) => {
              request.totalM3 = request.materials.reduce(
                (total, material) => total + parseInt(material.volume),
                0
              );
              return request;
            });
          }
          return data;
        })
      );
  }
}
