import { ResolveFn } from '@angular/router';
import { MaterialsService } from '../services/materials/materials.service';
import { inject } from '@angular/core';
import { MaterialModel } from '../models/material.model';
import { UserModel } from '../models/user.model';
import { UserService } from '../services/user/user.service';

export const materialsResolver: ResolveFn<void> = (route, state) => {
  const materialsService: MaterialsService = inject(MaterialsService);
  const currentMaterials: MaterialModel[] = materialsService.getMaterialsList();
  const currentUser: UserModel = inject(UserService).getUser();

  // Si los materiales ya están cargados o el usuario no está online,
  // no volvemos a hacer la llamada a la API.
  if (currentMaterials.length !== 0 || currentUser.status === 'offline') {
    console.log(
      'Materiales cargados o usuario offline, se obvia la llamada a la API.'
    );
    return;
  }
  console.log('Se cargarán los materiales.');
  return materialsService.setMaterials();
};
