import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryWindow } from '../models/deliveryWindow.model';
import { DeliveryWindowService } from '../services/delivery-windows/delivery-window.service';

@Pipe({
  name: 'deliveryWindow',
})
export class DeliveryWindowPipe implements PipeTransform {
  deliveryWindows: { [windowId: string]: DeliveryWindow };

  constructor(private deliveryWindowService: DeliveryWindowService) {
    this.deliveryWindows =
      this.deliveryWindowService.getDeliveryWindowsObject();
  }

  transform(windowId: string, position: 'start' | 'end'): string {
    if (position === 'start') {
      return this.deliveryWindows[windowId].startTime || 'No encontrado';
    } else {
      return this.deliveryWindows[windowId].endTime || 'No encontrado';
    }
  }
}
