import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from './user.service';
import { IdpConfig } from '../../config/idp.config';
import { MaterialsService } from '../materials/materials.service';
import { DeliveryWindowService } from '../delivery-windows/delivery-window.service';
import { PlantsService } from '../plants/plants.service';

@Injectable({
  providedIn: 'root',
})
export class IdpService {
  constructor(
    private readonly oauthService: OAuthService,
    private userService: UserService,
    private materialsService: MaterialsService,
    private deliveryWindowsService: DeliveryWindowService,
    private plantsService: PlantsService
  ) {
    this.configure().then((): void => {
      console.log('ipd configured');
    });
  }

  private async configure(): Promise<void> {
    this.oauthService.configure(IdpConfig);
    await this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then((result: boolean): void => {
        if (result) {
          this.oauthService.loadUserProfile().then(
            (fusionAuthUser: any): void => {
              console.log('idp - user: ', fusionAuthUser);
              this.userService.setUser({
                name: fusionAuthUser['info']['name'],
                status: 'online',
                email: fusionAuthUser['info']['email'],
                id: fusionAuthUser['info']['sub'],
                roles: fusionAuthUser['info']['roles'],
              });
              // Cargamos los maestros con el primer login.
              this.materialsService.setMaterials();
              this.deliveryWindowsService.setDeliveryWindows();
              this.plantsService.setPlants();
            },
            (error): void => {
              console.log('Error en loadUserProfile: ', error);
              this.logout();
            }
          );
        }
      })
      .catch((error): void => {
        console.log('Error en loadDiscoveryDocumentAndLogin: ', error);
        this.logout();
      });
  }

  public logout() {
    try {
      this.oauthService.logOut();
    } catch (error) {
      console.log('Error en logout: ', error);
      console.log(error);
    }
  }

  hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  getState(): string | undefined {
    return this.oauthService.state;
  }

  public getAccessToken(): string | null {
    return this.oauthService.getAccessToken();
  }
}
