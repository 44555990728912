export const environment = {
  production: false,
  apiUrl: 'https://tceqgc0m29.execute-api.us-west-2.amazonaws.com/',
  owlUrl: 'https://qacopecgti.owlchile.cl/info',
  backend: {
    endpoint: '',
  },
  permissions: {
    endpoint: '',
  },
  fusionauth: {
    clientId: 'b34bb9a3-c8d2-4fb0-bfb4-4f03e77a517b',
    issuer: 'https://copec-sa.fusionauth.io',
  },
};
