import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PlantModel } from '../../models/plant.model';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlantsService {
  private plants: PlantModel[] = [];
  private plantsObject: { [plantId: string]: PlantModel } = {};

  plantsChanged: BehaviorSubject<PlantModel[]> = new BehaviorSubject<
    PlantModel[]
  >(this.plants);
  plantsObjectChanged: BehaviorSubject<{ [plantId: string]: PlantModel }> =
    new BehaviorSubject(this.plantsObject);

  constructor(private http: HttpClient) {}

  /**
   * Configura el maestro de plantas
   */
  async setPlants(): Promise<void> {
    this._getPlantsAPI().subscribe({
      next: (plants: PlantModel[]): void => {
        this.plants = plants;
        plants.forEach((plant: PlantModel): void => {
          this.plantsObject[plant.plantId] = plant;
        });
        console.log('plantsObject', this.plantsObject);
        console.log('plantsList', this.plants);
        // Informamos a los componentes.
        this.plantsChanged.next(this.plants);
        this.plantsObjectChanged.next(this.plantsObject);
      },
    });
  }

  /**
   * Retorna el maestro de plantas
   */
  getPlantsObject(): { [index: string]: PlantModel } {
    return { ...this.plantsObject };
  }

  /**
   * Retorna la lista de plantas
   */
  getPlantsList(): PlantModel[] {
    return this.plants.slice();
  }

  /**
   * Consulta la API de plantas.
   * @private
   */
  private _getPlantsAPI(): Observable<PlantModel[]> {
    const url: string = `${environment.apiUrl}plants`;
    return this.http.get<{ data: PlantModel[] }>(url).pipe(
      map((response: { data: PlantModel[] }) => {
        return response.data;
      })
    );
  }
}
