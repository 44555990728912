import { Pipe, PipeTransform } from '@angular/core';
import { MaterialModel } from '../models/material.model';
import { MaterialsService } from '../services/materials/materials.service';

@Pipe({
  name: 'material',
})
export class MaterialPipe implements PipeTransform {
  materials: { [materialId: string]: MaterialModel };

  constructor(private materialsServie: MaterialsService) {
    this.materials = this.materialsServie.getMaterialsObject();
  }

  transform(materialId: string): string {
    return this.materials[materialId].shortDescription;
  }
}
