import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const IdpConfig: AuthConfig = {
  issuer: environment.fusionauth.issuer,
  clientId: environment.fusionauth.clientId,
  responseType: 'code',
  redirectUri: window.location.origin + '/home',
  logoutUrl: window.location.origin,
  scope: 'openid profile email offline_access roles',
  requireHttps: false,
  timeoutFactor: 0.8,
  strictDiscoveryDocumentValidation: true,
  useSilentRefresh: true,
  showDebugInformation: true,
};
