<header>
  <app-header></app-header>
</header>

<section>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body d-flex flex-column align-items-center">
            <!-- Alinea verticalmente al centro -->
            <button
              class="btn btn-outline-secondary mb-2"
              type="button"
              (click)="descargar()"
            >
              Descargar Formulario
            </button>
            <label class="form-label mb-0">Descargue el formato aceptado</label>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body d-flex flex-column align-items-center">
            <!-- Alinea verticalmente al centro -->
            <input
              type="file"
              id="fileInput"
              style="display: none"
              accept=".xlsx"
              (change)="onLoadExcel($event)"
            />
            <label for="fileInput" class="btn btn-outline-secondary mb-2"
              >Cargar Formulario</label
            >
            <label class="form-label mb-0"
              >Cargue sus solicitudes desde un archivo</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="loading" class="overlay">
  <div class="loader"></div>
</div>
