import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Observable } from 'rxjs';
import { PetitionerModel } from '../../models/Petitioner.model';

@Injectable({
  providedIn: 'root',
})
export class PetitionerService {
  urlBase: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getPetitioners(
    petitioners: Array<string>
  ): Observable<Array<PetitionerModel>> {
    const url = `${this.urlBase}petitioner?petitioner_id=`;
    let observables: Array<Observable<PetitionerModel>> = [];

    petitioners.forEach((petitioner) => {
      observables.push(
        this.http
          .get<{ data: PetitionerModel }>(url + petitioner)
          .pipe(map((res) => res.data))
      );
    });
    return forkJoin(observables).pipe(
      map((petitioners) => {
        //devuelvo el array ordenado por petitionerID
        petitioners.sort((a, b) => {
          if (a.petitionerId < b.petitionerId) {
            return -1;
          }
          if (a.petitionerId > b.petitionerId) {
            return 1;
          }
          return 0;
        });
        return petitioners;
      })
    );
  }
  getPetitionerById(petitionerId: string): Observable<PetitionerModel> {
    const url = `${this.urlBase}petitioner?petitioner_id=${petitionerId}`;
    return this.http.get<{ data: PetitionerModel }>(url).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
}
