import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrderModel } from '../../models/orders.model';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  endpoint: string = environment.apiUrl;
  nextToken = new BehaviorSubject<string>('');
  constructor(private http: HttpClient) {}

  getOrders(
    petitionerId: string,
    status: string,
    nextToken?: string
  ): Observable<OrderModel[]> {
    let url = `${this.endpoint}orders?petitioner_id=${petitionerId}&active=${status}`;
    if (nextToken) {
      url = `${url}&next_token=${nextToken}`;
    }
    return this.http.get<{ data: OrderModel[]; next_token: string }>(url).pipe(
      map((response) => {
        this.nextToken.next(response.next_token ?? null);
        const data = response.data ?? [];
        if (data?.length > 0) {
          data.map((order) => {
            order.totalM3 = order.materials.reduce(
              (total, material) => total + parseInt(material.volume),
              0
            );
            return order;
          });
        }
        return data;
      })
    );
  }

  rejectOrder(orderId: string): Observable<any> {
    let url = `${this.endpoint}order?order_id=${orderId}&action=reject`;
    return this.http.put(url, {});
  }
}
