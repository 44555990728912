<header>
  <app-header></app-header>
</header>

<section id="section-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-15">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-4 mb-15">
                    <label for="idSolicitante1" class="form-label"
                      >&nbsp;</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        id="iddebtor"
                        required
                        (change)="onChangeDebtor($event)"
                      >
                        <option selected disabled value="0">Deudor...</option>
                        <option
                          *ngFor="let debtor of debtorList"
                          [value]="debtor.debtor"
                          [attr.data-debtor]="debtor.name"
                        >
                          {{ debtor.debtor }} - {{ debtor.name }}
                        </option>
                      </select>
                      <button
                        class="btn btn-outline-secondary"
                        type="button"
                        (click)="refreshData()"
                      >
                        <i class="bi bi-arrow-clockwise"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-md-4 mb-15">
                    <label for="idSolicitante1" class="form-label"
                      >&nbsp;</label
                    >
                    <div class="input-group">
                      <select
                        class="form-select"
                        id="idSolicitante1"
                        required
                        (change)="pedidosOnChange($event)"
                      >
                        <option selected disabled value="0">
                          Solicitante...
                        </option>
                        <option
                          *ngFor="let petitioner of petitioners"
                          [value]="petitioner.petitionerId"
                          [attr.data-debtor]="petitioner.debtor"
                        >
                          {{ petitioner.petitionerId }} -
                          {{ petitioner.petitionerName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-15">
                <form action="" [formGroup]="searchForm">
                  <label for="inputBusqueda" class="form-label">&nbsp;</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="searchInput"
                      placeholder="Buscar..."
                      formControlName="search"
                    />
                    <span class="input-group-text">
                      <i class="bi bi-search"></i>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 mb-15">
      {{ message }}
    </div>
  </div>
</div>

<section id="section-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-15">
        <div class="card" *ngIf="requests.length > 0">
          <div class="card-header justify-content-between">
            <div class="card-title">
              SOLICITUDES &nbsp;<img
                src="../../assets/img/info2.jpg"
                width="20px"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                style="cursor: pointer"
                alt="info"
              />
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="accordion">
                  <div
                    class="accordion-item"
                    *ngFor="let request of filterRequests; let i = index"
                  >
                    <div class="accordion-header" id="heading{{ i }}">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'collapse' + i"
                      >
                        <!-- Contenido del botón -->

                        <div class="row w-100">
                          <div class="col-new">
                            ID: {{ request.requestId }}
                            <img
                              src="../../assets/img/copy.png"
                              width="20px"
                              (click)="copyToClipboard(request.requestId)"
                              alt="copy"
                            />
                          </div>
                          <div class="col-new" style="text-align: center">
                            Creación:
                            {{ request.creationDate | date : "dd-MM-yyyy" }}
                          </div>
                          <div class="col-new" style="text-align: center">
                            Entrega:
                            {{
                              request.requestedDeliveryDate
                                | date : "dd-MM-yyyy"
                            }}
                          </div>
                          <div class="col-new" style="text-align: center">
                            Ventana:
                            {{
                              request.requestedDeliveryTimeWindow
                                | deliveryWindow : "start"
                            }}
                            -
                            {{
                              request.requestedDeliveryTimeWindow
                                | deliveryWindow : "end"
                            }}
                          </div>
                          <div class="col-new" style="text-align: center">
                            Volumen:
                            {{ request.totalM3 }} m<sup>3</sup>
                          </div>
                          <div
                            class="col-new color-blue"
                            style="text-align: center"
                          >
                            {{ request.currentStatus }}
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      id="collapse{{ i }}"
                      class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'heading' + i"
                      data-bs-parent="#accordionExample + i"
                    >
                      <div class="accordion-body">
                        <div class="row w-100 mb-30"></div>
                        <div class="row">
                          <div class="col-md-6">
                            <table class="table table-striped table-responsive">
                              <caption></caption>
                              <thead>
                                <tr>
                                  <th scope="col">Material</th>
                                  <th scope="col">
                                    Volumen total (m<sup>3</sup>)
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">
                                <tr *ngFor="let material of request.materials">
                                  <td>
                                    {{ material.materialId | material }}
                                  </td>
                                  <td>{{ material.volume }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>OC:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ request.purchaseOrder || "--" }}
                              </div>
                              <div class="purchase-order-date-label">
                                <strong>Fecha:</strong>
                              </div>
                              <div class="purchase-order-date-details">
                                {{
                                  request.purchaseOrderDate
                                    ? (request.purchaseOrderDate
                                      | date : "dd-MM-yyyy")
                                    : "--"
                                }}
                              </div>
                              <div
                                *ngIf="request.comment !== ''"
                                class="comment-img"
                              >
                                <img
                                  src="../../assets/img/comentario2.png"
                                  width="30px"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="{{ request.comment }}"
                                  style="cursor: pointer"
                                  class="tooltip-custom"
                                  (click)="
                                    openModal(
                                      request.comment,
                                      request.requestId
                                    )
                                  "
                                  alt="comentario"
                                />
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>HES:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ request.HES || "--" }}
                              </div>
                              <div class="purchase-order-date-label">
                                <strong>Fecha:</strong>
                              </div>
                              <div class="purchase-order-date-details">
                                {{
                                  request.HESDate
                                    ? (request.HESDate | date : "dd-MM-yyyy")
                                    : "--"
                                }}
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>N° factura:</strong>
                              </div>
                              <div class="purchase-order-details">
                                @if(request.invoice && request.invoiceLink){
                                <a
                                  class="no-underline"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="{{ request.invoiceLink }}"
                                >
                                  {{ request.invoice || "--" }}</a
                                >
                                } @else {
                                {{ request.invoice || "--" }}
                                }
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>Guia de Despacho:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ request.dispatchGuide || "--" }}
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>Guía de traslado:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ request.transferGuide || "--" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 content-row">
                            <div class="content-flechas">
                              <div
                                class="flecha"
                                *ngFor="
                                  let status of request.statuses;
                                  let i = index
                                "
                                [ngClass]="{
                                  'linea-right':
                                    i === 0 && request.statuses.length > 1,
                                  'linea-left-right':
                                    i > 0 &&
                                    i < request.statuses.length - 1 &&
                                    request.statuses.length > 1,
                                  'linea-left':
                                    i === request.statuses.length - 1 &&
                                    request.statuses.length > 1
                                }"
                              >
                                <span
                                  [ngClass]="{
                                    'bg-blue':
                                      status.status === 'CREADA' ||
                                      status.status === 'PENDIENTE' ||
                                      status.status === 'PROCESANDO',
                                    'bg-green':
                                      status.status === 'ENVIADA' ||
                                      status.status === 'FALLIDA'
                                  }"
                                >
                                  <i
                                    [ngClass]="getClassFromArray(status.status)"
                                  ></i>
                                </span>
                                <h6>
                                  {{ status.status }}<br />{{
                                    status.statusDate | date : "dd-MM-yyyy"
                                  }}<br />{{
                                    status.statusDate | date : "HH:mm:ss"
                                  }}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="noResultsMessage" style="display: none">
                No se encontraron resultados.
              </div>
            </div>
          </div>
          <nav
            aria-label="Page navigation example"
            class="d-flex justify-content-center"
          >
            <ul class="pagination" id="paginar">
              <!-- Botón de retroceso -->
              <li class="page-item">
                <button
                  class="page-link"
                  (click)="previousPageRequests()"
                  [style.cursor]="
                    pageNumberRequests > 1 ? 'pointer' : 'not-allowed'
                  "
                  [disabled]="pageNumberRequests === 1"
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>

              <!-- Mostrar "..." y números de página adicionales dinámicamente -->
              <ng-container>
                <li class="page-item">
                  <a
                    class="page-link"
                    [class.active]="true"
                    [style.cursor]="'pointer'"
                  >
                    {{ pageNumberRequests }}
                  </a>
                </li>
              </ng-container>

              <!-- Botón de avance -->
              <li class="page-item">
                <button
                  class="page-link"
                  (click)="nextPageRequests(nextPageTokenRequests)"
                  [style.cursor]="
                    nextPageTokenRequests ? 'pointer' : 'not-allowed'
                  "
                  [disabled]="!nextPageTokenRequests"
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="section-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-15">
        <div class="card" *ngIf="orders.length > 0">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <div class="card-title flex-grow-1">
              PEDIDOS &nbsp;
              <img
                src="../../assets/img/info2.jpg"
                width="22px"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Lorem ipsum dolor sit amet consectetur adipiscing elit turpis praesent nibh tincidunt nisi ullamcorper et tempor iaculis eros, suscipit ultricies nostra semper vel conubia cursus ac nam dis placerat luctus porta vivamus montes. Nunc nisl fringilla maecenas velit pharetra integer massa facilisis"
                style="cursor: pointer"
                alt="info"
              />
              <div>
                <button
                  class="btn btn-outline-secondary mb-2 float-end"
                  type="button"
                  (click)="DescargarPedido()"
                  id="descargarexcel"
                >
                  Descargar Excel
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="accordion">
                  <div
                    class="accordion-item"
                    *ngFor="let order of filterOrders; let i = index"
                  >
                    <h2 class="accordion-header" id="heading2{{ i }}">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse2' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'collapse2' + i"
                      >
                        <!-- Contenido del botón -->

                        <div class="row w-100">
                          <div class="col-new">
                            ID:{{
                              order.orderId.toString().startsWith("0")
                                ? order.orderId.toString().substring(1)
                                : order.orderId
                            }}

                            &nbsp;
                            <img
                              src="../../assets/img/copy.png"
                              width="20px"
                              (click)="
                                copyToClipboard(
                                  order.orderId.toString().startsWith('0')
                                    ? order.orderId.toString().substring(1)
                                    : order.orderId
                                )
                              "
                              alt="copyToClipboard"
                            />
                          </div>
                          <div class="col-new" style="text-align: center">
                            Creación:
                            {{ order.creationDate | date : "dd-MM-yyyy" }}
                          </div>
                          <div class="col-new" style="text-align: center">
                            Entrega:
                            {{
                              order.requestedDeliveryDate | date : "dd-MM-yyyy"
                            }}
                          </div>
                          <div class="col-new" style="text-align: center">
                            Ventana:
                            {{
                              order.requestedDeliveryTimeWindow
                                | deliveryWindow : "start"
                            }}
                            -
                            {{
                              order.requestedDeliveryTimeWindow
                                | deliveryWindow : "end"
                            }}
                          </div>
                          <div class="col-new" style="text-align: center">
                            Volumen: {{ order.totalM3 }} m<sup>3</sup>
                          </div>
                          <div
                            class="col-new color-blue"
                            style="text-align: center"
                          >
                            {{ order.currentStatus }}
                            <a
                              id="look-map"
                              title="Ver camión en ruta"
                              (click)="onLookMap(order.orderId)"
                              *ngIf="order.currentStatus === 'EN RUTA'"
                            >
                              <i class="bi bi-search"></i>
                            </a>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapse2{{ i }}"
                      class="accordion-collapse collapse"
                      [attr.aria-labelledby]="'heading2' + i"
                      data-bs-parent="#accordionExample + i"
                    >
                      <div class="accordion-body">
                        <div class="row w-100 mb-30">
                          <div class="col-md-12 text-end">
                            <!-- Utilidad de alineación de Bootstrap -->
                            <button
                              type="button"
                              class="btn btn-danger"
                              [ngClass]="{
                                'active-button': !checkActiveButton(
                                  order.statuses
                                ),
                                'disabled-button': checkActiveButton(
                                  order.statuses
                                )
                              }"
                              [disabled]="checkActiveButton(order.statuses)"
                              [attr.title]="getTooltipText(order.statuses)"
                              (mouseover)="
                                showTooltip = shouldShowTooltip(order.statuses)
                              "
                              (mouseleave)="showTooltip = false"
                              onFocus="this.blur()"
                              data-bs-toggle="tooltip"
                              (click)="anularPedido(order.orderId)"
                            >
                              Anular Pedido
                            </button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <table class="table table-striped table-responsive">
                              <caption></caption>
                              <thead>
                                <tr>
                                  <th scope="col">Material</th>
                                  <th scope="col">
                                    Volumen total (m<sup>3</sup>)
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">
                                <tr *ngFor="let material of order.materials">
                                  <td>
                                    {{ material.materialId | material }}
                                  </td>
                                  <td>{{ material.volume }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>OC:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ order.purchaseOrder || "--" }}
                              </div>
                              <div class="purchase-order-date-label">
                                <strong>Fecha:</strong>
                              </div>
                              <div class="purchase-order-date-details">
                                {{
                                  order.purchaseOrderDate
                                    ? (order.purchaseOrderDate
                                      | date : "dd-MM-yyyy")
                                    : "--"
                                }}
                              </div>
                              <div
                                *ngIf="order.comment !== ''"
                                class="comment-img"
                              >
                                <img
                                  src="../../assets/img/comentario2.png"
                                  width="30px"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="{{ order.comment }}"
                                  style="cursor: pointer"
                                  class="tooltip-custom"
                                  (click)="
                                    openModal(order.comment, order.orderId)
                                  "
                                  alt="comentario"
                                />
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>HES:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ order.HES || "--" }}
                              </div>
                              <div class="purchase-order-date-label">
                                <strong>Fecha:</strong>
                              </div>
                              <div class="purchase-order-date-details">
                                {{
                                  order.HESDate
                                    ? (order.HESDate | date : "dd-MM-yyyy")
                                    : "--"
                                }}
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>N° Factura:</strong>
                              </div>
                              <div class="purchase-order-details">
                                @if( order.invoiceLink && order.invoice){
                                <a
                                  class="no-underline"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="{{ order.invoiceLink }}"
                                >
                                  {{ order.invoice || "--" }}</a
                                >
                                } @else {
                                {{ order.invoice || "--" }}
                                }
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>Guia de despacho:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ order.dispatchGuide || "--" }}
                              </div>
                            </div>
                            <div class="purchase-order-info">
                              <div class="purchase-order-label">
                                <strong>Guía de traslado:</strong>
                              </div>
                              <div class="purchase-order-details">
                                {{ order.transferGuide || "--" }}
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 content-row">
                            <div class="content-flechas">
                              <div
                                class="flecha"
                                *ngFor="
                                  let status of order.statuses;
                                  let i = index
                                "
                                [ngClass]="{
                                  'linea-right':
                                    i === 0 && order.statuses.length > 1,
                                  'linea-left-right':
                                    i > 0 &&
                                    i < order.statuses.length - 1 &&
                                    order.statuses.length > 1,
                                  'linea-left':
                                    i === order.statuses.length - 1 &&
                                    order.statuses.length > 1
                                }"
                              >
                                <span
                                  [ngClass]="{
                                    'bg-blue':
                                      status.status === 'CREADO' ||
                                      status.status === 'CARGA' ||
                                      status.status === 'PLANIFICADO' ||
                                      status.status === 'EN RUTA' ||
                                      status.status === 'ENTREGADO TOTALMENTE',
                                    'bg-red':
                                      status.status === 'NO ENTREGADO' ||
                                      status.status ===
                                        'RECIBIDO PARCIALMENTE' ||
                                      status.status ===
                                        'ENTREGADO PARCIALMENTE' ||
                                      status.status === 'DESPROGRAMADO' ||
                                      status.status === 'RECHAZADO'
                                  }"
                                >
                                  <i
                                    [ngClass]="getClassFromArray(status.status)"
                                  ></i>
                                </span>
                                <h6>
                                  {{ status.status }}<br />{{
                                    status.statusDate | date : "dd-MM-yyyy"
                                  }}<br />{{
                                    status.statusDate | date : "HH:mm:ss"
                                  }}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="noResultsMessage2" style="display: none">
                No se encontraron resultados.
              </div>
            </div>
          </div>
          <nav
            aria-label="Page navigation example"
            class="d-flex justify-content-center"
          >
            <ul class="pagination" id="paginars">
              <!-- Botón de retroceso -->
              <li class="page-item">
                <button
                  class="page-link"
                  (click)="previousPageOrders()"
                  [style.cursor]="
                    pageNumberOrders > 1 ? 'pointer' : 'not-allowed'
                  "
                  [disabled]="pageNumberOrders === 1"
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              <ng-container>
                <li class="page-item">
                  <a
                    class="page-link"
                    [class.active]="true"
                    [style.cursor]="'pointer'"
                  >
                    {{ pageNumberOrders }}
                  </a>
                </li>
              </ng-container>
              <!-- Botón de avance -->
              <li class="page-item">
                <button
                  class="page-link"
                  (click)="nextPageOrders(nextPageTokenOrders)"
                  [style.cursor]="
                    nextPageTokenOrders ? 'pointer' : 'not-allowed'
                  "
                  [disabled]="!nextPageTokenOrders"
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="loading" class="overlay">
  <div class="loader"></div>
</div>

<div
  #exampleModal
  class="modal fade"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          data-bs-dismiss="modal"
          (click)="closeModal()"
        ></button>
      </div>
      <div class="modal-body">
        {{ modalContent }}
      </div>
      <div class="modal-footer">
        <button
          #myButton
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          (click)="closeModal()"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
