import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import {
  HttpClientModule,
  provideHttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { OAuthModule, provideOAuthClient } from 'angular-oauth2-oidc';
import { PedidosComponent } from './pedidos/pedidos.component';
import { HeaderComponent } from './header/header.component';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { ModalComponent } from './modal/modal.component';
import { LoggingInterceptor } from './interceptors/auth-interceptor';
import { PedidoReferenciaComponent } from './pedido-referencia/pedido-referencia.component';
import { PedidoMasivoComponent } from './pedido-masivo/pedido-masivo.component';
import { EditSolicitudComponent } from './edit-solicitud/edit-solicitud.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { IdpService } from './services/user/idp.service';
import { DeliveryWindowPipe } from './pipes/delivery-window.pipe';
import { MaterialPipe } from './pipes/material.pipe';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PedidosComponent,
    HeaderComponent,
    SolicitudComponent,
    PedidoReferenciaComponent,
    PedidoMasivoComponent,
    EditSolicitudComponent,
    NotificacionesComponent,
    DeliveryWindowPipe,
    MaterialPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [
    IdpService,
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
