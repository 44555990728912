import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { IdpService } from '../services/user/idp.service';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit, OnDestroy {
  usuario: UserModel;

  private userSubscription: Subscription = new Subscription();

  constructor(
    private _userService: UserService,
    private _idpService: IdpService
  ) {
    this.usuario = this._userService.getUser();
  }

  ngOnInit(): void {
    this.userSubscription = this._userService.userChanged.subscribe(
      (user: UserModel): void => {
        console.log('header - usuario', this.usuario);
        if (user.status === 'online') {
          this.usuario = user;
        }
      }
    );
  }

  /**
   * Ejecuta el cierre de sesión del usuario.
   */
  onLogout() {
    this._idpService.logout();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    console.log('header - subscripciones deshechas.');
  }
}
