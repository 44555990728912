import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { IdpService } from '../services/user/idp.service';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  usuario: UserModel;

  constructor(
    private _userService: UserService,
    private idpService: IdpService,
    private router: Router
  ) {
    this.usuario = this._userService.getUser();
  }

  async ngOnInit(): Promise<void> {
    console.log('login - user', this._userService.getUser());
    console.log(
      'login - hasValidAccessToken: ',
      this.idpService.hasValidAccessToken()
    );
    if (this.idpService.hasValidAccessToken()) {
      console.log('ir al home');
      this.router
        .navigate(['/home'])
        .then(() => console.log('navigate to home'));
    } else {
      console.log('iniciar flujo de login');
    }
  }
}
