<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 mt-15 mb-15">
      <div class="card">
        <div class="card-body">
          <!--INICIO DE LOGO-->
          <div class="logo">
            <img src="../../assets/images/Copeclogo.png" alt="" />
          </div>

          <!--INICIO DE DROP USER-->
          <div class="btn-group drop-user">
            <div class="btn-group dropstart" role="group">
              <button
                type="button"
                class="btn btn-user"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-person-fill"></i>
                <span class="visually-hidden">Toggle Dropstart</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li class="header-drop-menu">
                  <h6>
                    <h6>{{ usuario.email }}</h6>
                  </h6>
                  <span>Usuario</span>
                </li>
                <li>
                  <a class="dropdown-item" type="button">
                    <i class="bi bi-person-fill"></i> Perfil
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/notificaciones">
                    <i class="bi bi-bell-fill"></i>Notificaciones
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" type="button">
                    <i class="bi bi-info-circle-fill"></i>
                    Información
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" type="button" (click)="onLogout()">
                    <i class="bi bi-box-arrow-right"></i> Cerrar Sesión
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!--INICIO DE NAVBAR-->
          <nav
            class="navbar navbar-expand-lg navbar-light drop-user pb-0 pt-0 menu-right"
          >
            <div class="navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" routerLink="/home">Home</a>
                </li>
                <li class="nav-item dropdown" routerLinkActive="active">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Generar Solicitud
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <!-- Aquí puedes agregar los elementos de tu dropdown -->
                    <!-- Por ejemplo, puedes agregar enlaces a diferentes páginas -->
                    <li>
                      <a class="dropdown-item" routerLink="/pedidos"
                        >Unitaria</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" routerLink="/pedido-masivo"
                        >Masiva via formulario</a
                      >
                    </li>
                    <li class="dropdown-divider"></li>
                    <li>
                      <a class="dropdown-item" routerLink="/solicitud"
                        >Solicitudes sin enviar</a
                      >
                    </li>
                    <!-- Puedes agregar más elementos según lo necesites -->
                  </ul>
                </li>
                <li class="nav-item" routerLinkActive="active">
                  <a class="nav-link" routerLink="/historial">Historial</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
