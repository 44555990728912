<header>
  <app-header></app-header>
</header>

<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="input-group align-items-center">
                <label
                  for="orderId"
                  class="form-label"
                  style="margin-right: 20px"
                >
                  Ingresa un número de referencia
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="orderId"
                  placeholder="Ingrese el número de pedido"
                />
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="buscarDatos()"
                >
                  Buscar
                </button>
              </div>

              <div class="col-md-12" style="margin-top: 70px">
                <!-- Cambiamos el ancho de la columna -->
                <ng-container *ngIf="datosPedidos && datosPedidos.data">
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped">
                      <caption></caption>
                      <!-- Añadimos la clase "table-striped" para alternar colores en las filas -->
                      <thead class="text-center">
                        <tr>
                          <th style="font-size: 18px; width: 200px">
                            Fecha Pedido
                          </th>
                          <!-- Aumentamos el tamaño del texto en las cabeceras -->
                          <th style="font-size: 18px; width: 400px">
                            Solicitante
                          </th>
                          <th style="font-size: 18px; width: 400px">
                            Materiales
                          </th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr>
                          <td style="font-size: 18px">
                            {{
                              datosPedidos.data.requestedDeliveryDate
                                | date : "dd-MM-yyyy"
                            }}
                          </td>
                          <td style="font-size: 18px">
                            {{ petitionerIDbuscar }} -
                            {{ petitionerNamebuscar }}
                          </td>
                          <td style="font-size: 18px">
                            <p
                              *ngFor="
                                let material of mappedMaterials | keyvalue
                              "
                            >
                              {{ material.value.shortDescription }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="UsarPedido(datosPedidos.data)"
                    >
                      Usar Pedido de referencia
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="overlay">
  <div class="loader"></div>
</div>
