export const iconClassForOrders: { [key: string]: string } = {
  CREADA: 'bi bi-card-checklist',
  CREADO: 'bi bi-card-checklist',
  PLANIFICADO: 'bi bi-calendar2-check',
  CARGA: 'bi bi-fuel-pump',
  'EN RUTA': 'bi bi-truck',
  'ENTREGADO TOTALMENTE': 'bi bi-boxes',
  'ENTREGADO PARCIALMENTE': 'bi bi-box-seam',
  'NO ENTREGADO': 'bi bi-x-lg',
  DESPROGRAMADO: 'bi bi-calendar-x',
  RECHAZADO: 'bi bi-x-lg',
  PROCESANDO: 'bi bi-arrow-repeat',
  ENVIADA: 'bi bi-send',
  'RECIBIDO PARCIALMENTE': 'bi bi-box-seam',
};
