import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { DeliveryWindowService } from '../services/delivery-windows/delivery-window.service';
import { DeliveryWindow } from '../models/deliveryWindow.model';
import { UserModel } from '../models/user.model';
import { UserService } from '../services/user/user.service';

export const deliveryWindowsResolver: ResolveFn<void> = (route, state) => {
  const deliveryWindowsService: DeliveryWindowService = inject(
    DeliveryWindowService
  );
  const currentDeliveryWindows: DeliveryWindow[] =
    deliveryWindowsService.getDeliveryWindows();
  const currentUser: UserModel = inject(UserService).getUser();

  // Si las ventanas ya están cargadas o el usuario no está online,
  //   // no volvemos a hacer la llamada a la API.
  if (currentDeliveryWindows.length !== 0 || currentUser.status === 'offline') {
    console.log(
      'Ventanas de entrega cargadas o usuario offline, se obvia la llamada a la API.'
    );
    return;
  }
  console.log('Se cargarán las ventanas de entrega.');
  return deliveryWindowsService.setDeliveryWindows();
};
