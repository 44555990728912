import {
  Component,
  OnDestroy,
  ElementRef,
  ViewChild,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, forkJoin, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import { MaterialModel } from '../models/material.model';
import { DeliveryWindow } from '../models/deliveryWindow.model';
import { DeliveryWindowService } from '../services/delivery-windows/delivery-window.service';
import { MaterialsService } from '../services/materials/materials.service';
import { UserProfileModel } from '../models/userProfile.model';

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.css'],
  providers: [DatePipe],
})
export class SolicitudComponent implements OnInit, OnDestroy {
  usuario: UserModel;
  userProfile: UserProfileModel;
  deliveryWindows: { [requestId: string]: DeliveryWindow } = {};
  materials: { [materialId: string]: MaterialModel } = {};
  endpoint: string = environment.apiUrl;
  nuevaRespuesta: any;
  loading: boolean = true;
  datosPedidos: any;
  horasInicioOrder: { [key: string]: string } = {};
  horasFinOrder: { [key: string]: string } = {};
  selectAll: boolean = false;
  modalContent: string | undefined;
  modalTitle: string | undefined;
  volumenPorMaterialPorSolicitud: any = {};
  petitionerNameBuscar: { [key: string]: string } = {};
  volumenTotal: { [key: string]: number } = {};

  countSelection: number = 0;

  private ngUnsubscribe = new Subject<void>();
  private userSubscription: Subscription = new Subscription();
  private materialsSubscription: Subscription = new Subscription();
  private deliveryWindowsSubscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private userService: UserService,
    private deliveryWindowsService: DeliveryWindowService,
    private materialsService: MaterialsService
  ) {
    this.usuario = this.userService.getUser();
    this.userProfile = this.userService.getUserProfile();
    this.deliveryWindows =
      this.deliveryWindowsService.getDeliveryWindowsObject();
    this.materials = this.materialsService.getMaterialsObject();
  }
  @ViewChild('exampleModal') exampleModal!: ElementRef;

  ngOnInit(): void {
    this.initializeModal();

    this.userSubscription = this.userService.userChanged.subscribe(
      (user: UserModel): void => {
        if (user.status === 'online') {
          this.usuario = user;
          this.userProfile = this.userService.getUserProfile();
          console.log('solicitud - usuario', this.usuario);
          this.loading = true;
          this.getShoppingCartRequests();
        }
      }
    );

    this.materialsSubscription =
      this.materialsService.materialsObjectChanged.subscribe(
        (materialsObject: { [materialId: string]: MaterialModel }): void => {
          this.materials = materialsObject;
        }
      );
    this.deliveryWindowsSubscription =
      this.deliveryWindowsService.deliveryWindowsObjectChanged.subscribe(
        (deliveryWindows: { [requestId: string]: DeliveryWindow }): void => {
          this.deliveryWindows = deliveryWindows;
        }
      );
  }
  getMaterialIds(solicitudId: string): string[] {
    return Object.keys(this.volumenPorMaterialPorSolicitud[solicitudId] || {});
  }

  private initializeModal() {
    if (this.exampleModal) {
      this.exampleModal.nativeElement.addEventListener(
        'hidden.bs.modal',
        () => {
          // Realizar cualquier acción adicional al cerrar el modal
        }
      );
    }
  }
  closeModal() {
    const modal = new bootstrap.Modal(this.exampleModal.nativeElement);
    modal.hide();
  }

  mostrarAlerta(
    texto: string,
    icon: any,
    redireccionar: boolean = false,
    ruta?: string
  ) {
    Swal.fire({
      title: texto,
      icon: icon,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002948',
    }).then((result) => {
      if (result.isConfirmed && redireccionar) {
        // El usuario ha hecho clic en el botón OK y se debe redirigir
        // Verificar si se proporcionó una ruta válida
        if (ruta && ruta != '/solicitud') {
          this.router.navigate([ruta]);
        } else {
          window.location.reload();
        }
      }
    });
  }

  getShoppingCartRequests(): void {
    const nuevoEndpointUrl: string = `${this.endpoint}/shopping-cart-requests?created_by=${this.userProfile.userId}`;

    this.httpClient.get<any>(nuevoEndpointUrl).subscribe({
      next: (response: any) => {
        this.nuevaRespuesta = response;
        const shoppingCartRequests = this.nuevaRespuesta.data;
        if (!shoppingCartRequests || shoppingCartRequests.length === 0) {
          this.loading = false;
          return;
        } else {
          shoppingCartRequests.forEach((request: any): void => {
            // Ventanas de Entrega
            this.horasInicioOrder[request.shoppingCartRequestsId] =
              this.deliveryWindows[
                request.requestedDeliveryTimeWindow
              ].startTime;
            this.horasFinOrder[request.shoppingCartRequestsId] =
              this.deliveryWindows[request.requestedDeliveryTimeWindow].endTime;

            // Inicializar el objeto de mapa para esta solicitud
            this.volumenPorMaterialPorSolicitud[
              request.shoppingCartRequestsId
            ] = {};
            this.volumenTotal[request.shoppingCartRequestsId] = 0;

            // Iterar sobre los materiales de la solicitud y agregar su volumen al material correspondiente
            request.materials.forEach(
              (material: {
                materialId: string;
                unitOfMeasure: string;
                volume: string;
              }): void => {
                this.volumenTotal[request.shoppingCartRequestsId] += parseInt(
                  material.volume
                );

                // Verificar si el material ya existe en volumenPorMaterialPorSolicitud
                if (
                  !this.volumenPorMaterialPorSolicitud[
                    request.shoppingCartRequestsId
                  ].hasOwnProperty(material.materialId)
                ) {
                  // Si no existe, establecer el volumen y el nombre del material
                  this.volumenPorMaterialPorSolicitud[
                    request.shoppingCartRequestsId
                  ][material.materialId] = {
                    volume: parseInt(material.volume),
                    description:
                      this.materials[material.materialId].shortDescription ||
                      'Nombre no encontrado',
                  };
                } else {
                  // Si existe, sumar el volumen al volumen total del material
                  this.volumenPorMaterialPorSolicitud[
                    request.shoppingCartRequestsId
                  ][material.materialId].volume += parseInt(material.volume);
                }
              }
            );

            const endpoint = `${this.endpoint}petitioner?petitioner_id=${request.petitionerId}`;
            this.httpClient.get(endpoint).subscribe({
              next: (response2: any) => {
                this.petitionerNameBuscar[request.shoppingCartRequestsId] =
                  response2.data.petitionerName;
              },
              error: (error: any) => {
                this.mostrarAlerta(
                  `Error al obtener el solicitante para el pedido ${this.datosPedidos.orderId}: ${error.message}`,
                  'error',
                  true,
                  '/home'
                );
              },
            });
          });

          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0); // Establecer la hora actual a medianoche para comparar solo las fechas

          // Filtrar los elementos del arreglo shoppingCartRequests
          shoppingCartRequests.filter((request: any) => {
            // Convertir la cadena de la fecha requestedDeliveryDate a objeto Date
            const [year, month, day] = request.requestedDeliveryDate.split('-');
            const requestedDeliveryDate = new Date(
              Number(year),
              Number(month) - 1,
              Number(day)
            ); // El mes se indexa desde 0 (enero es 0)
            requestedDeliveryDate.setHours(0, 0, 0, 0); // Establecer la hora de la fecha solicitada a medianoche

            // Comparar las fechas
            return requestedDeliveryDate.getTime() < currentDate.getTime(); // Utilizar '<' para incluir solo las fechas anteriores al día actual
          });

          this.loading = false;
        }
      },
      error: (error: any) => {
        this.loading = false;
        this.mostrarAlerta(
          'Ha ocurrido un error inesperado al obtener los pedidos',
          'error'
        );
      },
    });
  }

  borrarElementos(elementosParaBorrar: any[]): void {
    const solicitudesHttp = elementosParaBorrar.map((solicitud: any) => {
      const itemIdFormatted = solicitud.shoppingCartRequestsId.replace(
        /#/g,
        '%23'
      );
      const endpoint = `${this.endpoint}/shopping-cart-request?shopping_cart_requests_id=${itemIdFormatted}`;
      return this.httpClient.delete(endpoint);
    });

    forkJoin(solicitudesHttp).subscribe(
      (respuestas) => {
        this.loading = true;
        this.mostrarAlerta(
          'Todas las solicitudes enviadas exitosamente',
          'success',
          true,
          '/home'
        );

        // Puedes realizar acciones adicionales después de eliminar todos los elementos.
      },
      (error) => {
        this.mostrarAlerta(
          'Error al eliminar elementos',
          'error',
          true,
          '/home'
        );
      }
    );
  }

  toggleSeleccion(solicitud: any): void {
    solicitud.seleccionada = !solicitud.seleccionada;
    this.selectAll = false;
    if (solicitud.seleccionada === true) {
      this.countSelection += 1;
    } else {
      this.countSelection -= 1;
    }
  }
  toggleSelectAll(): void {
    // Cambia el estado de la selección de todos los elementos
    this.selectAll = !this.selectAll;
    // Actualiza el estado de cada solicitud en consecuencia
    this.nuevaRespuesta.data.forEach((solicitud: any) => {
      solicitud.seleccionada = this.selectAll;
    });

    if (this.selectAll === true) {
      this.countSelection = this.nuevaRespuesta.data.length;
    } else {
      this.countSelection = 0;
    }
  }

  enviarsolicitud2(): void {
    const solicitudesSeleccionadas = this.nuevaRespuesta.data.filter(
      (solicitud: any) => solicitud.seleccionada
    );

    if (solicitudesSeleccionadas.length === 0) {
      this.mostrarAlerta(
        'Debes seleccionar al menos una solicitud para enviar.',
        'error'
      );
      return;
    } else {
      const solicitudesHttp = solicitudesSeleccionadas.map((solicitud: any) => {
        const solicitudParaEnviar = {
          createdBy: this.userProfile.userId,
          debtor: solicitud.debtor,
          petitionerId: solicitud.petitionerId,
          requestedDeliveryTimeWindow: solicitud.requestedDeliveryTimeWindow,
          requestedDeliveryDate: solicitud.requestedDeliveryDate,
          purchaseOrder: solicitud.purchaseOrder,
          purchaseOrderDate: solicitud.purchaseOrderDate,
          purchaseOrderExternalReference:
            solicitud.purchaseOrderExternalReference,
          HES: solicitud.HES,
          HESDate: solicitud.HESDate,
          HESExternalReference: solicitud.HESExternalReference,
          comment: solicitud.comment,
          materials: solicitud.materials.map((material: any) => ({
            materialId: material.materialId,
            volume: material.volume,
            unitOfMeasure: material.unitOfMeasure,
          })),
        };

        const endpointEnviarSolicitud = `${this.endpoint}request`;
        this.loading = true;

        return this.httpClient.post(
          endpointEnviarSolicitud,
          solicitudParaEnviar
        );
      });

      // Una vez que las solicitudes marcadas han sido enviadas exitosamente, elimina solo esas del backend
      forkJoin(solicitudesHttp).subscribe(
        (respuestas) => {
          this.loading = true;
          this.borrarElementos(solicitudesSeleccionadas);
          // Puedes realizar acciones adicionales después de enviar todas las solicitudes.
        },
        (error) => {
          this.mostrarAlerta(
            'Error al enviar al menos una solicitud',
            'error',
            true,
            '/solicitud'
          );
        }
      );
    }
  }

  openModal(
    comment: string,
    id: string,
    nombre: string,
    comentario: string
  ): void {
    this.modalContent = comment;
    this.modalTitle = comentario + ' del solicitante: ' + id + '-' + nombre;
    this.initializeModal();
    const modal = new bootstrap.Modal(this.exampleModal.nativeElement);
    modal.show();
  }

  confirmarEliminacion(shoppingCartRequestsId: string) {
    Swal.fire({
      title: '¿Está seguro que desea eliminar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // Si el usuario hace clic en "Sí, eliminar"
        this.eliminarElemento(shoppingCartRequestsId);
      } else {
        // Si el usuario hace clic en "Cancelar" o cierra la alerta
        // No es necesario hacer nada
      }
    });
  }
  eliminarElemento(shoppingCartRequestsId: string) {
    const orderIdEncoded = shoppingCartRequestsId.replace(/#/g, '%23');
    const nuevoEndpointUrl = `${this.endpoint}shopping-cart-request?shopping_cart_requests_id=${orderIdEncoded}`;
    this.httpClient.delete<any>(nuevoEndpointUrl).subscribe(
      (response) => {
        this.mostrarAlerta(response.message, 'success', true, '/solicitud');
      },
      (error) => {
        this.mostrarAlerta('Error al eliminar el elemento:', 'error');
        // Manejar el error si es necesario
      }
    );
  }

  editarSolicitud(shoppingCartRequestsId: string, user: string) {
    this.router.navigate(['/edit-solicitud', shoppingCartRequestsId]);
  }

  alldelete() {
    const solicitudesSeleccionadas = this.nuevaRespuesta.data.filter(
      (solicitud: any) => solicitud.seleccionada
    );

    if (solicitudesSeleccionadas.length === 0) {
      this.mostrarAlerta(
        'Debes seleccionar al menos una solicitud para eliminar.',
        'error'
      );
      return;
    } else {
      solicitudesSeleccionadas.map((solicitud: any) => {
        const orderIdEncoded = solicitud.shoppingCartRequestsId.replace(
          /#/g,
          '%23'
        );
        const nuevoEndpointUrl = `${this.endpoint}shopping-cart-request?shopping_cart_requests_id=${orderIdEncoded}`;

        this.httpClient.delete<any>(nuevoEndpointUrl).subscribe(
          (response) => {
            this.mostrarAlerta(response.message, 'success', true, '/solicitud');
          },
          (error) => {
            this.mostrarAlerta('Error al eliminar el elemento:', 'error');
            // Manejar el error si es necesario
          }
        );
      });
    }
  }
  onKeyDown(event: KeyboardEvent) {
    // No necesitas realizar ninguna acción específica en este caso
  }

  onKeyPress(event: KeyboardEvent) {
    // No necesitas realizar ninguna acción específica en este caso
  }

  onKeyUp(event: KeyboardEvent) {
    // No necesitas realizar ninguna acción específica en este caso
  }
  selectedHasErrors(): boolean {
    let solicitudesSeleccionadas: Array<any> = this.nuevaRespuesta.data.filter(
      (solicitud: any) => solicitud.seleccionada
    );
    return solicitudesSeleccionadas.every((solicitud) => {
      return !solicitud.hasError;
    });
  }

  get solicitudesMensaje(): string {
    if (!this.nuevaRespuesta?.data || this.loading) {
      return '';
    }

    const count = this.nuevaRespuesta.data.length;

    if (count === 1) {
      return `Usted tiene ${count} solicitud sin enviar`;
    } else if (count >= 2) {
      return `Usted tiene ${count} solicitudes sin enviar`;
    }

    return '';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.userSubscription.unsubscribe();
    this.materialsSubscription.unsubscribe();
    this.deliveryWindowsSubscription.unsubscribe();
    console.log('solicitud - subscripciones deshechas.');
  }
}
