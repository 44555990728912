import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import Swal from 'sweetalert2';
import { SignedUrlResponseModel } from '../models/signed-url-response.model';
import { UserProfileModel } from '../models/userProfile.model';

@Component({
  selector: 'app-pedido-masivo',
  templateUrl: './pedido-masivo.component.html',
  styleUrl: './pedido-masivo.component.css',
})
export class PedidoMasivoComponent implements OnInit, OnDestroy {
  usuario: UserModel;
  userProfile: UserProfileModel;
  loading: boolean = false;

  private userSubscription: Subscription = new Subscription();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private _userService: UserService
  ) {
    this.usuario = this._userService.getUser();
    this.userProfile = this._userService.getUserProfile();
  }

  ngOnInit() {
    this.userSubscription = this._userService.userChanged.subscribe(
      (user: UserModel): void => {
        console.log('pedido-masivo - usuario', this.usuario);
        if (user.status === 'online') {
          this.usuario = user;
          this.userProfile = this._userService.getUserProfile();
        }
      }
    );
  }

  mostrarAlerta(
    texto: string,
    icon: any,
    redireccionar: boolean = false,
    ruta?: string
  ) {
    Swal.fire({
      title: texto,
      icon: icon,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002948',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed && redireccionar) {
        // El usuario ha hecho clic en el botón OK y se debe redirigir
        // Verificar si se proporcionó una ruta válida
        if (ruta) {
          this.router.navigate([ruta]);
        } else {
          console.error(
            'No se proporcionó una ruta válida para la redirección.'
          );
        }
      }
    });
  }

  descargar() {
    this.loading = true;
    const endpoint: string = `${environment.apiUrl}generate-form?user_id=${this.userProfile.userId}`;
    this.httpClient.get(endpoint).subscribe({
      next: (response2: any) => {
        const excelUrl = response2.data.url;
        const link = document.createElement('a');
        link.href = excelUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.loading = false;
      },
      error: (error: any) => {},
    });
  }

  onLoadExcel(event: any): void {
    this.loading = true;
    const url: string = `${environment.apiUrl}shopping-cart-requests?created_by=${this.userProfile.userId}`;

    this.httpClient.get<any>(url).subscribe({
      next: (response: any) => {
        const shoppingCartRequests = response.data;
        if (shoppingCartRequests === undefined) {
          const file: File = event.target.files[0];
          this.getSignedUrlAndUpload(file);
        } else {
          Swal.fire({
            title:
              'Tienes solicitudes sin enviar, al cargar otro archivo se borraran. ¿Desea continuar?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Aceptar', // Texto del botón de aceptar
            cancelButtonText: 'Cancelar', // Texto del botón de cancelar
            confirmButtonColor: '#002948',
          }).then((result): void => {
            if (result.isConfirmed) {
              this.loading = true;
              this.borrarElementos(shoppingCartRequests);
              setTimeout(() => {
                const file: File = event.target.files[0];
                this.getSignedUrlAndUpload(file);
              }, 7000);
            } else {
              this.loading = false;
            }
          });
        }
      },
      error: (error: any) => {
        // Manejar errores de la solicitud HTTP
        console.error(
          'Error al cargar las solicitudes de carritos de compras:',
          error
        );
        this.loading = false;
      },
    });
  }

  borrarElementos(elementosParaBorrar: any[]): void {
    const solicitudesHttp = elementosParaBorrar.map((solicitud: any) => {
      const itemIdFormatted = solicitud.shoppingCartRequestsId.replace(
        /#/g,
        '%23'
      );
      const endpoint: string = `${environment.apiUrl}shopping-cart-request?shopping_cart_requests_id=${itemIdFormatted}`;
      return this.httpClient.delete(endpoint);
    });

    // Usamos forkJoin para ejecutar todas las solicitudes HTTP simultáneamente
    forkJoin(solicitudesHttp).subscribe({
      next: (response: any[]) => {
        // Aquí puedes agregar cualquier lógica adicional que necesites después de eliminar los elementos
      },
      error: (error: any) => {
        // Aquí puedes manejar el error de eliminación
      },
    });
  }

  /**
   * Consulta la API pare recibir una url pre-firmada de carga de archivos.
   * @param file
   */
  getSignedUrlAndUpload(file: File): void {
    const endpoint: string = `${environment.apiUrl}signed-url?operation=upload`;
    this.httpClient.get<{ data: SignedUrlResponseModel }>(endpoint).subscribe({
      next: (response: { data: SignedUrlResponseModel }): void => {
        this.uploadFileToS3(response.data, file);
      },
      error: (error: any): void => {
        this.loading = false;
        this.mostrarAlerta(
          `Error obteniendo datos de carga de archivo: ${error}`,
          'error',
          true,
          '/pedido-masivo'
        );
      },
    });
  }

  /**
   * Crea la petición de carga del archivo a S3.
   * @param {SignedUrlResponseModel} data datos para cargar el archivo.
   * @param {File} file
   */
  uploadFileToS3(data: SignedUrlResponseModel, file: File) {
    const formData: FormData = new FormData();
    formData.append('key', data.fields.key);
    formData.append('AWSAccessKeyId', data.fields.AWSAccessKeyId);
    formData.append(
      'x-amz-security-token',
      data.fields['x-amz-security-token']
    );
    formData.append('policy', data.fields.policy);
    formData.append('signature', data.fields.signature);
    formData.append('file', file);

    const headers: HttpHeaders = new HttpHeaders().set('skip-interceptor', '');
    this.httpClient.post(data.url, formData, { headers: headers }).subscribe(
      (): void => {
        this.loading = false;
        this.mostrarAlerta(
          'Sus solicitudes fueron enviadas a validar. Espere el correo de confirmación para revisar las Solicitudes sin enviar.',
          'success',
          true
        );
      },
      (error): void => {
        this.loading = false;
        this.mostrarAlerta(
          `Error al cargar solicitudes: ${error}`,
          'error',
          true,
          '/pedido-masivo'
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    console.log('pedido-masivo - subscripciones deshechas.');
  }
}
