import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { DeliveryWindow } from '../../models/deliveryWindow.model';

@Injectable({
  providedIn: 'root',
})
export class DeliveryWindowService {
  private deliveryWindows: DeliveryWindow[] = [];
  private deliveryWindowsObject: { [windowId: string]: DeliveryWindow } = {};

  deliveryWindowsChanged: BehaviorSubject<DeliveryWindow[]> =
    new BehaviorSubject<DeliveryWindow[]>(this.deliveryWindows);
  deliveryWindowsObjectChanged: BehaviorSubject<{
    [windowId: string]: DeliveryWindow;
  }> = new BehaviorSubject<{ [windowId: string]: DeliveryWindow }>(
    this.deliveryWindowsObject
  );

  constructor(private http: HttpClient) {}

  /**
   * Configura el maestro de ventanas de entrega.
   */
  async setDeliveryWindows(): Promise<void> {
    this.getDeliveryWindowsAPI().subscribe({
      next: (deliveryWindows: DeliveryWindow[]): void => {
        this.deliveryWindows = deliveryWindows;
        deliveryWindows.forEach((deliveryWindow: DeliveryWindow): void => {
          this.deliveryWindowsObject[deliveryWindow.windowId] = deliveryWindow;
        });
        console.log('deliveryWindowsObject', this.deliveryWindowsObject);
        console.log('deliveryWindows', this.deliveryWindows);
        // Informamos a los componentes.
        this.deliveryWindowsChanged.next(this.deliveryWindows);
        this.deliveryWindowsObjectChanged.next(this.deliveryWindowsObject);
      },
      error: (error: any): void => {
        console.error(error);
      },
    });
  }

  /**
   * Retorna el maestro de ventanas de entrega.
   */
  getDeliveryWindowsObject(): { [windowId: string]: DeliveryWindow } {
    return { ...this.deliveryWindowsObject };
  }

  /**
   * Retorna el maestro de ventanas de entrega.
   */
  getDeliveryWindows(): DeliveryWindow[] {
    return this.deliveryWindows.slice();
  }

  /**
   * Consulta la API de las ventanas de entrega.
   * @private
   */
  private getDeliveryWindowsAPI(): Observable<DeliveryWindow[]> {
    let url: string = `${environment.apiUrl}delivery_windows`;
    return this.http.get<{ data: DeliveryWindow[] }>(url).pipe(
      map((response: { data: DeliveryWindow[] }) => {
        return response.data;
      })
    );
  }
}
