import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { IdpService } from '../services/user/idp.service';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import { tap, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import * as bootstrap from 'bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pedido-referencia',
  templateUrl: './pedido-referencia.component.html',
  styleUrl: './pedido-referencia.component.css',
})
export class PedidoReferenciaComponent {
  orderId: any;
  loading: boolean = false;
  datosPedidos: any;
  petitionerNamebuscar: any;
  petitionerIDbuscar: any;
  mappedMaterials: {
    [key: string]: {
      materialId: string;
      description: string;
      shortDescription: string;
    };
  } = {};
  endpoint: string = environment.apiUrl;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _userService: UserService,
    private _idpService: IdpService
  ) {
    this.loading = false;
  }
  @ViewChild('myButton') myButton!: ElementRef;

  mostraAlerta(
    texto: string,
    icon: any,
    redireccionar: boolean = false,
    ruta?: string
  ) {
    Swal.fire({
      title: texto,
      icon: icon,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002948',
    }).then((result) => {
      if (result.isConfirmed && redireccionar) {
        // El usuario ha hecho clic en el botón OK y se debe redirigir
        // Verificar si se proporcionó una ruta válida
        if (ruta) {
          this.router.navigate([ruta]);
        } else {
          console.error(
            'No se proporcionó una ruta válida para la redirección.'
          );
        }
      }
    });
  }
  UsarPedido(datosPedidos: any) {
    // Lógica para usar los datos del pedido
    // Por ejemplo, puedes enviar los datos a través de la navegación
    this.router.navigate(['/pedidos'], {
      state: { datosPedidos: datosPedidos },
    });
  }
  buscarDatos() {
    this.orderId = (<HTMLInputElement>document.getElementById('orderId')).value;
    this.loading = true;
    if (this.orderId) {
      const orderIdEncoded = this.orderId.replace(/#/g, '%23');
      const endpointUrl = `${this.endpoint}order?order_id=${orderIdEncoded}`;
      this.httpClient.get(endpointUrl).subscribe({
        next: (response: any) => {
          this.datosPedidos = response;

          this.handleMaterialsIds(
            response.data.materials.map((material: any) => material.materialId)
          );

          const endpoint = `${this.endpoint}petitioner?petitioner_id=${response.data.petitionerId}`;
          this.httpClient.get(endpoint).subscribe({
            next: (response2: any) => {
              this.petitionerNamebuscar = response2.data.petitionerName;
              this.petitionerIDbuscar = response.data.petitionerId;
              this.loading = false;
            },
            error: (error: any) => {
              this.mostraAlerta(
                `Error al obtener el solicitante para el pedido ${this.datosPedidos.orderId}: ${error.message}`,
                'error',
                true,
                '/home'
              );
            },
          });
        },
        error: (error: any) => {
          this.loading = false;
          if (error.status === 400) {
            this.mostraAlerta(error.error.message, 'error');
          } else {
            this.mostraAlerta(
              'Ha ocurrido un error inesperado al obtener los pedidos',
              'error',
              true,
              '/home'
            );
          }
        },
      });
    } else {
      this.mostraAlerta('Debe ingresar una orden ', 'info');
      this.loading = false;
    }
  }

  handleMaterialsIds(materialIds: any[]): void {
    // Obtener todos los materiales
    const endpoint = `${this.endpoint}materials`;

    this.httpClient.get(endpoint).subscribe((response: any) => {
      if (response.success && response.data) {
        // Filtrar los materiales que coincidan con los IDs proporcionados
        const filteredMaterials = response.data.filter((material: any) =>
          materialIds.includes(material.materialId)
        );

        // Mapear los materiales filtrados al objeto mappedMaterials
        this.mappedMaterials = filteredMaterials.reduce(
          (result: any, material: any) => {
            result[material.materialId] = {
              materialId: material.materialId,
              description: material.description,
              shortDescription: material.shortDescription,
              // Agrega otras propiedades según tus necesidades
            };
            return result;
          },
          {}
        );
      }
    });
  }
}
