import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private oauthService: OAuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Verificar si la solicitud HTTP contiene el encabezado personalizado 'skip-interceptor'
    if (req.headers.has('skip-interceptor')) {
      // Clonar la solicitud sin agregar el encabezado de autorización
      const modifiedReq: HttpRequest<any> = req.clone({
        headers: req.headers.delete('Authorization'),
      });
      // Eliminar la cabecera 'skip-interceptor'.
      const finalReq: HttpRequest<any> = modifiedReq.clone({
        headers: modifiedReq.headers.delete('skip-interceptor'),
      });
      return next.handle(finalReq);
    }

    // Si la solicitud no contiene el encabezado personalizado, agregar el encabezado de autorización
    const accessToken = this.oauthService.getAccessToken();
    if (accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    // Pasar la solicitud clonada con el encabezado de autorización al siguiente manipulador
    return next.handle(req);
  }
}
