import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { PlantsService } from '../services/plants/plants.service';
import { PlantModel } from '../models/plant.model';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';

export const plantsResolver: ResolveFn<void> = (route, state) => {
  const plantsService: PlantsService = inject(PlantsService);
  const currentPlants: PlantModel[] = plantsService.getPlantsList();
  const currentUser: UserModel = inject(UserService).getUser();

  // Si las plantas ya están cargadas o el usuario no está online,
  // no volvemos a hacer la llamada a la API.
  if (currentPlants.length !== 0 || currentUser.status === 'offline') {
    console.log(
      'Plantas cargadas o usuario offline, se obvia la llamada a la API.'
    );
    return;
  }
  console.log('Se cargarán las plantas.');
  return plantsService.setPlants();
};
