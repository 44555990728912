import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { UserService } from '../services/user/user.service';
import { UserModel } from '../models/user.model';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap';
import { DebtorModel } from '../models/debtors.model';
import { UserProfileModel } from '../models/userProfile.model';
import { PetitionerService } from '../services/petitioner/petitioner.service';
import { PetitionerModel } from '../models/Petitioner.model';
import { OrderService } from '../services/order/order.service';
import { RequestsService } from '../services/requests/requests.service';
import { OrderModel } from '../models/orders.model';
import { RequestModel } from '../models/request.model';
import { iconClassForOrders } from '../const/icons';
import { DeliveryWindowService } from '../services/delivery-windows/delivery-window.service';
import { MaterialModel } from '../models/material.model';
import { MaterialsService } from '../services/materials/materials.service';
import { DeliveryWindow } from '../models/deliveryWindow.model';
import { FormBuilder, FormGroup } from '@angular/forms';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('exampleModal') exampleModal!: ElementRef;
  @ViewChild('myButton') myButton!: ElementRef;

  usuario: UserModel;
  userProfile: UserProfileModel;
  deliveryWindows: { [requestId: string]: DeliveryWindow } = {};
  materials: { [materialId: string]: MaterialModel } = {};
  endpoint: string = environment.apiUrl;
  petitionersIds: string[] = [];
  petitioners: Array<PetitionerModel> = [];
  orders: OrderModel[] = [];
  orderId: string;
  loading: boolean = false;
  requests: RequestModel[] = [];
  requestId: string;
  loadingMostrar: boolean = false;
  loadingMostrar2: boolean = false;
  debtorList: DebtorModel[] = [];
  message: any;
  modalContent: string | undefined;
  modalTitle: string | undefined;
  pageNumberOrders: number = 1;
  pageNumberRequests: number = 1;
  numeroPag: number = 1;
  showTooltip: boolean = false;
  nextPageTokenOrders: string = '';
  nextPageTokenRequests: string = '';
  previousTokenOrders: string[] = [];
  previousTokenRequests: string[] = [];
  actualPetitioner: any;
  iconClass = iconClassForOrders;
  searchForm: FormGroup;
  private userSubscription: Subscription = new Subscription();
  private materialsSubscription: Subscription = new Subscription();
  private deliveryWindowsSubscription: Subscription = new Subscription();
  filterRequests: RequestModel[] = [];
  filterOrders: OrderModel[] = [];
  selectedStatus: string = 'TRUE';

  constructor(
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router,
    private petitionerService: PetitionerService,
    private orderService: OrderService,
    private requestsService: RequestsService,
    private deliveryWindowsService: DeliveryWindowService,
    private materialsService: MaterialsService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.orderId = '';
    this.requestId = '';
    this.message = '';
    this.previousTokenOrders[1] = '';
    this.previousTokenRequests[1] = '';
    this.usuario = this.userService.getUser();
    this.userProfile = this.userService.getUserProfile();
    this.deliveryWindows =
      this.deliveryWindowsService.getDeliveryWindowsObject();
    this.materials = this.materialsService.getMaterialsObject();
    this.searchForm = this.fb.group({
      search: [''],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((urlSegment) => {
      let rutaActual = urlSegment.map((segment) => segment.path).join('/');
      if (rutaActual === 'historial') {
        this.selectedStatus = 'FALSE';
      } else {
        this.selectedStatus = 'TRUE';
      }
    });
    this.searchForm.get('search')?.valueChanges.subscribe((value) => {
      this.filterList(value);
    });

    this.userSubscription = this.userService.userChanged.subscribe(
      (user: UserModel): void => {
        if (user.status === 'online') {
          this.usuario = user;
          this.userProfile = this.userService.getUserProfile();
          this.deliveryWindows =
            this.deliveryWindowsService.getDeliveryWindowsObject();
          this.loading = true;
          this.setUserProfile();
          console.log('home - usuario', this.usuario);
          console.log('home - userProfile', this.userProfile);
        }
      }
    );
    this.materialsSubscription =
      this.materialsService.materialsObjectChanged.subscribe(
        (materialsObject: { [materialId: string]: MaterialModel }): void => {
          this.materials = materialsObject;
        }
      );
    this.deliveryWindowsSubscription =
      this.deliveryWindowsService.deliveryWindowsObjectChanged.subscribe(
        (deliveryWindowsObject: {
          [windowId: string]: DeliveryWindow;
        }): void => {
          this.deliveryWindows = deliveryWindowsObject;
        }
      );

    this.orderService.nextToken.subscribe((nextpage) => {
      this.nextPageTokenOrders = nextpage;
    });
    this.requestsService.nextToken.subscribe((nextpage) => {
      this.nextPageTokenRequests = nextpage;
    });
    this.initializeModal();
    this.numeroPag = 1;
  }
  filterList(searchTerm: string): void {
    this.filterRequests = this.requests.filter((x) =>
      x.requestId.includes(searchTerm)
    );
    this.filterOrders = this.orders.filter((x) =>
      x.orderId.includes(searchTerm)
    );
  }
  nextPageOrders(token: string) {
    this.loading = true;
    this.previousTokenOrders[this.pageNumberOrders + 1] =
      this.nextPageTokenOrders;
    this.orderService
      .getOrders(this.actualPetitioner, this.selectedStatus, token)
      .subscribe((orders) => {
        this.pageNumberOrders += 1;
        this.orders = orders;
        this.filterOrders = this.orders;
        this.loading = false;
      });
  }
  previousPageOrders() {
    this.loading = true;
    this.orderService
      .getOrders(
        this.actualPetitioner,
        this.selectedStatus,
        this.previousTokenOrders[this.pageNumberOrders - 1]
      )
      .subscribe((orders) => {
        this.pageNumberOrders -= 1;
        this.orders = orders;
        this.filterOrders = this.orders;
        this.loading = false;
      });
  }
  nextPageRequests(token: string) {
    this.loading = true;
    this.previousTokenRequests[this.pageNumberRequests + 1] =
      this.nextPageTokenRequests;
    this.requestsService
      .getRequest(this.actualPetitioner, this.selectedStatus, token)
      .subscribe((requests) => {
        this.pageNumberRequests += 1;
        this.requests = requests;
        this.filterRequests = this.requests;
        this.loading = false;
      });
  }
  previousPageRequests() {
    this.loading = true;
    this.requestsService
      .getRequest(
        this.actualPetitioner,
        this.selectedStatus,
        this.previousTokenRequests[this.pageNumberRequests - 1]
      )
      .subscribe((requests) => {
        this.pageNumberRequests -= 1;
        this.requests = requests;
        this.filterRequests = this.requests;
        this.loading = false;
      });
  }
  openModal(comment: string, id: string): void {
    this.modalContent = comment;
    this.modalTitle = 'Comentario del ID: ' + id;
    this.initializeModal();
    const modal = new bootstrap.Modal(this.exampleModal.nativeElement);
    modal.show();
  }

  closeModal() {
    const modal = new bootstrap.Modal(this.exampleModal.nativeElement);
    modal.hide();
  }

  private initializeModal() {
    if (this.exampleModal) {
      this.exampleModal.nativeElement.addEventListener(
        'hidden.bs.modal',
        () => {}
      );
    }
  }
  mostrarAlerta(
    texto: string,
    icon: any,
    redireccionar: boolean = false,
    ruta?: string
  ) {
    Swal.fire({
      title: texto,
      icon: icon,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#002948',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed && redireccionar) {
        // El usuario ha hecho clic en el botón OK y se debe redirigir
        // Verificar si se proporcionó una ruta válida
        this.loading = false;
        if (ruta && ruta != '/home') {
          this.router
            .navigate([ruta])
            .then(() => console.log(`navegar a ${ruta}`));
        } else {
          this.refreshData();
        }
      }
    });
  }

  setUserProfile(): void {
    this.debtorList = this.userProfile.debtors;
    if (this.debtorList.length === 1) {
      //TODO cambiar formulario reactivo
      const selectedDebtorId: string = this.debtorList[0].debtor;
      const iddebtorElement: HTMLSelectElement = document.getElementById(
        'iddebtor'
      ) as HTMLSelectElement;
      if (iddebtorElement) {
        // Establecer el valor seleccionado en el select
        iddebtorElement.value = selectedDebtorId;
        // Llamar manualmente a onChangeDebtor con el ID seleccionado
        this.onChangeDebtor({ target: { value: selectedDebtorId } });
      }
    }
    this.loading = false;
  }

  procesarPetitioners(): void {
    this.loading = true;
    this.petitionerService
      .getPetitioners(this.petitionersIds)
      .subscribe((petitioners) => {
        this.petitioners = petitioners;
        if (this.petitioners.length === 1) {
          //TODO refactor a formulario
          const selectedsolicitanteId = this.petitioners[0].petitionerId; // Obtener el ID del primer deudor
          // Obtener el elemento select 'iddebtor'
          const idsolicitanteElement = <HTMLSelectElement>(
            document.getElementById('idSolicitante1')
          );
          if (idsolicitanteElement) {
            // Establecer el valor seleccionado en el select
            idsolicitanteElement.value = String(selectedsolicitanteId);
            setTimeout(() => {
              const event = new Event('change', { bubbles: true });
              idsolicitanteElement.dispatchEvent(event);
            }, 500);
          }
          this.loading = false;
        } else {
          this.requests = [];
          this.orders = [];
          this.loading = false;
        }
      });
  }

  getTooltipText(statuses: any[]): string | null {
    if (
      statuses.some(
        (status) =>
          status.status !== 'CREADO' &&
          status.status !== 'PLANIFICADO' &&
          status.status !== 'PLANIFICADO'
      )
    ) {
      return 'El pedido se encuentra en carga y no se puede modificar';
    }
    return null;
  }

  shouldShowTooltip(statuses: any[]): boolean {
    return statuses.some((status) => status.status === 'CREADO');
  }

  checkActiveButton(statuses: any[]): boolean {
    const activeStatuses = [
      'CREADO',
      'PLANIFICADO',
      'PEDIDO CON BLOQUEO DE CRÉDITO',
    ];
    return statuses.some((status) => !activeStatuses.includes(status.status));
  }

  onChangeSolicitante(event: any): void {
    this.pageNumberOrders = 1;
    this.pageNumberRequests = 1;
    this.loading = true;
    forkJoin({
      orders: this.orderService.getOrders(event, this.selectedStatus),
      requests: this.requestsService.getRequest(event, this.selectedStatus),
    }).subscribe({
      next: (response) => {
        if (response.requests?.length === 0 && response.orders?.length === 0) {
          this.mostrarAlerta('No hay datos para ese solicitante.', 'info');
          this.loading = false;
        }
        this.orders = response.orders;
        this.requests = response.requests;
        this.filterOrders = this.orders;
        this.filterRequests = this.requests;
        this.loading = false;
      },
      error: (error: any) => {
        console.log(error);
        this.mostrarAlerta(error.message + ' para pedidos', 'error');
        this.loading = false;
      },
    });
  }

  pedidosOnChange(event: any): void {
    this.loading = true;
    this.actualPetitioner = event.target.value;
    this.onChangeSolicitante(event.target.value);
  }

  copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => console.log(`texto: ${text} copiado`));
  }

  refreshData(): void {
    const searchInputElement = document.getElementById(
      'searchInput'
    ) as HTMLInputElement;
    if (searchInputElement) {
      searchInputElement.value = ''; // Establecer el valor del input a una cadena vacía
    }
    const idSolicitante1 = (<HTMLSelectElement>(
      document.getElementById('idSolicitante1')
    )).value;
    if (idSolicitante1 !== '0') {
      this.onChangeSolicitante(idSolicitante1);
    } else {
      this.setUserProfile();
    }
  }

  DescargarPedido() {
    const idSolicitante1 = (<HTMLSelectElement>(
      document.getElementById('idSolicitante1')
    )).value;
    const endpoint = `${this.endpoint}/excel-info-orders?petitioner_id=${idSolicitante1}&active=TRUE`;
    this.loading = true;
    this.httpClient.get(endpoint).subscribe({
      next: (response2: any) => {
        const excelUrl: string = response2.data;
        const link = document.createElement('a');
        link.href = excelUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.loading = false;
      },
      error: (error: any): void => {
        console.log(error);
      },
    });
  }

  onChangeDebtor(event: any) {
    // Encontrar el deudor seleccionado en la lista de deudores
    this.searchForm.get('search')?.setValue('');
    const selectedDebtor = this.debtorList.find(
      (debtor) => debtor.debtor === event.target.value
    );
    // Verificar si se encontró el deudor seleccionado
    if (selectedDebtor) {
      // Acceder a la lista de solicitantes del deudor seleccionado
      this.petitionersIds = selectedDebtor.petitioners;
      this.procesarPetitioners();
      const idSolicitante1Element = document.getElementById(
        'idSolicitante1'
      ) as HTMLSelectElement;
      if (idSolicitante1Element) {
        idSolicitante1Element.value = '0';
        this.loadingMostrar = false;
        this.loadingMostrar2 = false;
      }
    } else {
      this.mostrarAlerta('Deudor no encontrado', 'error');
    }
  }

  anularPedido(id: string) {
    Swal.fire({
      title: '¿Está seguro que desea anular el pedido?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, anular',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.orderService.rejectOrder(id).subscribe({
          next: (): void => {
            this.loading = false;
            this.refreshData();
            this.mostrarAlerta(
              `El pedido  ${id}  ha sido anulado`,
              'info',
              true,
              '/home'
            );
          },
          error: (error: any) => {
            if (error.status === 400 && error.error && error.error.message) {
              const errorMessage = error.error.message;
              this.mostrarAlerta(errorMessage, 'error', true, '/home');
            } else {
              // Handle other errors
              this.mostrarAlerta(
                `Hubo un error al anular el pedido ${id}.`,
                'error',
                true,
                '/home'
              );
            }
            this.refreshData();
          },
        });
      }
    });
  }
  getClassFromArray(status: string): string {
    return this.iconClass[status];
  }

  /**
   * Abre una nueva ventana usando la API de OWL que muestra el transporte en ruta.
   * @param {string} orderId ID del pedido.
   */
  async onLookMap(orderId: string): Promise<void> {
    // El ID del pedido debe tener 10 caracteres.
    orderId = orderId.padStart(10, '0');
    const searchDateYYYYMMDD: string = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    let idTrx: string = '03';
    idTrx += orderId.substring(0, 5);
    idTrx += '0000000000';
    idTrx += searchDateYYYYMMDD.substring(0, 4);
    idTrx += orderId.substring(5, 10);
    idTrx += '0000000000';
    idTrx += searchDateYYYYMMDD.substring(4, 8);
    idTrx = btoa(idTrx);

    const url: string = `${environment.owlUrl}?idtrx=${idTrx}`;
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.materialsSubscription.unsubscribe();
    this.deliveryWindowsSubscription.unsubscribe();
    console.log('home - subscripciones deshechas.');
  }
}
