<header>
  <app-header></app-header>
</header>

<section>
  <div class="container-fluid">
    <div class="row">
      <!--INICIO DE COL-MD-6 -->
      <div class="col-md-6 mb-30">
        <div class="card">
          <div class="card-body">
            <form
              [formGroup]="editFrom"
              class="row g-3 needs-validation"
              novalidate
              id="FormLeft"
            >
              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Debtor <span>*</span></label
                >
                <select
                  class="form-select"
                  id="iddebtor"
                  required
                  (change)="onChangedebtor($event)"
                  formControlName="debtor"
                >
                  <option disabled value="">Seleccionar...</option>
                  <option
                    *ngFor="let debtor of debtorList"
                    [value]="debtor.debtor"
                    [attr.data-debtor]="debtor.name"
                  >
                    {{ debtor.debtor }} - {{ debtor.name }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>
              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Solicitante <span>*</span></label
                >
                <select
                  class="form-select"
                  id="idSolicitante"
                  required
                  formControlName="petitioner"
                  (change)="changePetitioner()"
                >
                  <!-- TODO cambiar on change para que recoozca lo materiales del petitioner y reseetee el camion y la panta   -->
                  <option value="">Seleccionar...</option>
                  <option
                    *ngFor="let petitioner of solicitante"
                    [value]="petitioner.petitionerId"
                    [attr.data-debtor]="petitioner.debtor"
                  >
                    {{ petitioner.petitionerId }} -
                    {{ petitioner.petitionerName }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>

              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Fecha de entrega <span>*</span></label
                >
                <input
                  type="date"
                  id="fecha_entrega"
                  value="solicitudSeleccionada.requestedDeliveryDate"
                  class="form-control"
                  placeholder="dd/mm/yyyy"
                  [min]="minDate"
                  [max]="maxDate"
                  (change)="selectDate($event)"
                />
              </div>

              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Ventana de entrega <span>*</span></label
                >
                <select class="form-select" id="ventana_entrega" required>
                  <option selected disabled value="">Seleccionar...</option>
                  <option *ngFor="let i of rangoHorario" [value]="i.windowId">
                    {{ i.description }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>

              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Planta <span>*</span></label
                >
                <select
                  class="form-select"
                  id="plantaId"
                  required
                  (change)="onChangePlanta($event, true)"
                >
                  <option selected value="0">Seleccionar...</option>
                  <option
                    *ngFor="let i of planta"
                    [value]="i.plantId"
                    [selected]="i.plantId === idPlanta"
                  >
                    {{ i.name }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>

              <div class="col-md-4">
                <label for="validationCustom04" class="form-label"
                  >Tipo de camión <span>*</span></label
                >
                <select
                  class="form-select"
                  id="tipo_camion_id"
                  required
                  (change)="onChangeCamion($event)"
                >
                  <option value="0">Seleccionar...</option>
                  <option
                    *ngFor="let i of volumen"
                    [value]="i.volumesDistribution"
                    [selected]="i.volumesDistribution === idCamion"
                  >
                    {{ i.volume }} m<sup>3</sup> - {{ i.volumesDistribution }}
                  </option>
                </select>
                <div class="invalid-feedback">Please select a valid state.</div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-30">
        <div class="card">
          <div class="card-body">
            <form class="row g-3" id="FormRight">
              <div class="col-md-4">
                <label for="validationDefault01" class="form-label"
                  >Numero de OC</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="numerooc"
                  required
                  placeholder="123901576"
                  autocomplete="off"
                  (input)="autoFillDate($event, 'fecha_oc')"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault02" class="form-label"
                  >Fecha de OC</label
                >
                <input
                  type="date"
                  id="fecha_oc"
                  class="form-control"
                  placeholder="9-12-2023"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault03" class="form-label"
                  >Ref. Externa de OC</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="refoc"
                  required
                  placeholder="123901576"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault01" class="form-label"
                  >Numero de HES</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="numerohes"
                  required
                  placeholder="123901576"
                  autocomplete="off"
                  (input)="autoFillDate($event, 'fecha_hes')"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault02" class="form-label"
                  >Fecha de HES</label
                >
                <input
                  type="date"
                  id="fecha_hes"
                  class="form-control"
                  placeholder="9-12-2023"
                />
              </div>

              <div class="col-md-4">
                <label for="validationDefault03" class="form-label"
                  >Ref. Externa de HES</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="refhes"
                  required
                  placeholder="123901576"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div *ngIf="validaSelectTipoCation" class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title">
              Materiales <span style="color: red">*</span>
            </div>
            <p class="capitalize-first">
              Debe escoger la capacidad del camión entre los materiales
            </p>
          </div>
          <div class="card-body">
            <table class="table table-striped table-responsive">
              <caption></caption>
              <thead>
                <tr>
                  <th scope="col">Material</th>
                  <th
                    *ngFor="let i of volumenData"
                    scope="col"
                    class="chk{{ i }}"
                  >
                    {{ i }}m<sup>3</sup>
                  </th>
                  <th scope="col" class="text-center">
                    Total por Material (m<sup>3</sup>)
                  </th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr *ngFor="let material of mappedMaterials | keyvalue">
                  <th scope="row">
                    {{ material.value.shortDescription }}
                  </th>
                  <td *ngFor="let volume of volumenData">
                    <div class="form-check form-switch form-switch-sm">
                      <input
                        class="form-check-input {{
                          material.value.materialId
                        }} {{ material.key }}_{{
                          volume
                        }} material-checkbox prendido-{{ material.key }}_{{
                          volume
                        }}"
                        [value]="volume"
                        [checked]="checkDinamic(material.key, volume)"
                        type="checkbox"
                        id="{{ material.key }}_{{ volume }}"
                        (change)="
                          onCheckboxChange($event, volume, material.key)
                        "
                      />
                    </div>
                  </td>
                  <td class="text-center" id="{{ material.key }}">
                    {{
                      sumaCheck2 ? sumaCheck2[+material.key] : "No disponible"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title">Observación</div>
          </div>
          <div class="card-body">
            <label for="exampleFormControlTextarea1" class="form-label"
              >Escriba su observación</label
            >
            <textarea
              class="form-control"
              id="observacion"
              rows="8"
              style="resize: none"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-40 mb-40 botones">
        <button class="btn btn-secondary" (click)="cancelarSolicitud()">
          Cancelar
        </button>
        <button class="btn btn-primary" (click)="editarSolicitud()">
          Editar Solicitud
        </button>
      </div>
    </div>
  </div>
</section>

<div *ngIf="loading" class="overlay">
  <div class="loader"></div>
</div>

<script src="../../assets/js/script.js"></script>
